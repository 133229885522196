import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel, getCurrentProductType } from 'store/sector/selectors'

import { formatNumber } from 'utils/formatters'

const timeframeFromProps = (state, props) => props.timeframe
const vapeCategoryFromProps = (state, props) => props.vapeCategory
const vapeChannelFromProps = (state, props) => props.vapeChannel
const periodFromProps = (state, props) => props.period

function formatOosInvCapData({ data }) {
  const { last, previous } = data

  const result = { percent: '-', variation: 0 }
  if (!last) return result

  const lastWeekPercentage = last * 100
  result.percent = formatNumber(lastWeekPercentage)

  if (!previous) return result

  const previousPercentage = previous * 100
  result.variation = formatNumber(lastWeekPercentage - previousPercentage)

  return result
}

function formatDistroData({ data }) {
  if (isEmpty(data)) return null
  const processed = {
    percent: data.salesDistro,
    variation: data.salesDistroDiff,
    stores: data.storeDistro,
    storesVariation: data.storeDistroDiff,
    skus: data.skuDistro,
    skusVariation: data.skuDistroDiff
  }

  return processed
}

const healthCheckDefaultValue = {
  skus: '-',
  stores: '-',
  oos: { percent: '-', variation: 0 },
  distro: { percent: '-', variation: 0 },
  inventoryCaptures: { percent: '-', variation: 0 }
}

export const planningHealthCheckData = createSelector(
  dataSourceFromSectorLevel,
  getCurrentProductType,
  timeframeFromProps,
  vapeCategoryFromProps,
  vapeChannelFromProps,
  periodFromProps,
  (sector, activeProductType, timeframe, vapeCategory, vapeChannel, period) => {
    if (isEmpty(sector) || !sector) return healthCheckDefaultValue

    const healthCheckData =
      sector[`healthcheck-${activeProductType}-${vapeCategory}-timeframe-${timeframe}-${vapeChannel}-${period}`]
    if (!healthCheckData || isEmpty(healthCheckData)) return healthCheckDefaultValue

    return {
      oos: formatOosInvCapData({ data: healthCheckData.oos }),
      distro: formatDistroData({ data: healthCheckData.distro }) || healthCheckDefaultValue.distro,
      inventoryCaptures: formatOosInvCapData({ data: healthCheckData.inv })
    }
  }
)
