import isEmpty from 'lodash/isEmpty'
import snakeCase from 'lodash/snakeCase'

import { addEntities, mergeEntities, setEntities } from 'store/actions'
import { addToSectorReducer } from 'store/cycleCampaigns/reducer'
import * as extraHubReducer from 'store/extraHub/reducer'
import * as inventoryReducer from 'store/inventory/reducer'
import * as priceCaptureCompliance from 'store/priceCaptureCompliance/reducers'
import * as selloutReducer from 'store/Sellout/reducer'

import {
  addAmplifyExtraHubActivityValuesToRegion,
  addAmplifyExtraHubEngagementValuesToRegion,
  addAmplifyExtraHubFiltersValuesToRegion,
  addAmplifyExtraHubGeographyValuesToRegion,
  addAmplifyExtraHubTrendingValuesToRegion,
  addAmplifyInventoryOosBrandsValuesToRegion,
  addAmplifyInventoryOosFiltersValuesToRegion,
  addAmplifyInventoryOosGeographyValuesToRegion,
  addAmplifyInventoryOosMaterialValuesToRegion,
  addAmplifyInventoryOosTrendingValuesToRegion,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToRegion,
  addAmplifyPriceCaptureComplianceFiltersValuesToRegion,
  addAmplifyPriceCaptureComplianceFootprintValuesToRegion,
  addAmplifyPriceCaptureComplianceGeographyValuesToRegion,
  addAmplifyPriceComplianceEdlpComplianceValuesToRegion,
  addAmplifyPriceComplianceGeographyComplianceValuesToRegion,
  addAmplifyPriceComplianceGeographyFiltersValuesToRegion,
  addAmplifyPriceComplianceStrategyComplianceValuesToRegion,
  addAmplifySelloutGeographyValuesToRegion,
  addAmplifySelloutValuesToRegion,
  addCycleCampaignsToRegion,
  addDistroBrandDataToRegion,
  addDistroGeographyDataToRegion,
  addDistroMaterialDataToRegion,
  addDistroSummaryDataToRegion,
  addDistroTrendedDataToRegion,
  addPlanningCycleCampaignValuesToRegion,
  addPlanningEngagementValuesToRegion,
  addPlanningHealthCheckValuesToRegion,
  addPlanningPriceCheckValuesToRegion,
  addPlanningSellinValuesToRegion,
  addPlanningSelloutValuesToRegion,
  addSellinGeographyToRegion,
  addSellinOrderCompletionToRegion,
  addSellinRunRateToRegion
} from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, { payload }) => {
    if (!payload.regions) return state

    return Object.entries(payload.regions).reduce((acc, [id, region]) => {
      return Object.assign(acc, {
        [id]: region
      })
    }, Object.assign({}, state))
  },
  [setEntities]: (state, action) => action.payload.regions || state,
  [mergeEntities]: (state, { payload }) => {
    if (!payload.regions) return state
    return Object.entries(payload.regions).reduce((acc, [id, region]) => {
      return Object.assign(acc, {
        [id]: {
          ...(acc[id] || {}),
          ...region
        }
      })
    }, Object.assign({}, state))
  },
  [addSellinRunRateToRegion]: (state, { payload: { id, activeProductType, vapeCategory, unitOfMeasure, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-run-rate-${activeProductType}-${vapeCategory}-${unitOfMeasure}`]: result
      }
    })
  },
  [addSellinGeographyToRegion]: (
    state,
    {
      payload: {
        sectorId: id,
        currentProductType,
        geography,
        period,
        range,
        vapeCategory,
        unitOfMeasure,
        offset,
        result,
        sortBy,
        sortDirection
      }
    }
  ) => {
    const key = `sellin-geography-${currentProductType}-${geography}-${period}-${vapeCategory}-${unitOfMeasure}-${sortBy}-${sortDirection}-${range}-${offset}`
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [key]: result
      }
    })
  },
  [addSellinOrderCompletionToRegion]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-order-completion`]: result
      }
    })
  },
  [addDistroTrendedDataToRegion]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, period, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-trended-${activeProductType}-${vapeCategory}-${vapeChannel}-${period}`]: result
      }
    })
  },
  [addDistroBrandDataToRegion]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result, sortBy, direction, period } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-brand-${activeProductType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}-${period}`]: result
      }
    })
  },
  [addDistroGeographyDataToRegion]: (
    state,
    {
      payload: {
        sectorId: id,
        productType,
        geography,
        vapeCategory,
        vapeChannel,
        offset,
        filters,
        result,
        sortBy,
        direction,
        period
      }
    }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-geography-${productType}-${geography}-${vapeCategory}-${vapeChannel}-offset-${offset}-${
          filters && !isEmpty(filters)
            ? Object.entries(filters)
                .map(([key, value]) => `${key}-${snakeCase(value)}`)
                .join('-')
            : 'no-filters'
        }-${sortBy}-${direction}-${period}`]: result
      }
    })
  },
  [addDistroMaterialDataToRegion]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result, sortBy, direction, period } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-material-${activeProductType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}-${period}`]: result
      }
    })
  },
  [addDistroSummaryDataToRegion]: (
    state,
    { payload: { sectorId: id, productType, vapeCategory, vapeChannel, period, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-summary-${productType}-${vapeCategory}-${vapeChannel}-${period}`]: result
      }
    })
  },
  [addPlanningSellinValuesToRegion]: (state, { payload: { id, result, type, vapeCategory, unitOfMeasure } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-${type}-${vapeCategory}-${unitOfMeasure}`]: result
      }
    })
  },
  [addPlanningSelloutValuesToRegion]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}`]: result
      }
    })
  },
  [addAmplifySelloutValuesToRegion]: selloutReducer.mergeMain,
  [addAmplifySelloutGeographyValuesToRegion]: selloutReducer.mergeGeography,
  [addPlanningHealthCheckValuesToRegion]: (
    state,
    { payload: { id, productType, result, timeframe, vapeCategory, vapeChannel, period } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`healthcheck-${productType}-${vapeCategory}-timeframe-${timeframe}-${vapeChannel}-${period}`]: result
      }
    })
  },
  [addPlanningPriceCheckValuesToRegion]: (state, { payload: { id, activeProductType, data } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`pricecheck-${activeProductType}`]: data
      }
    })
  },
  [addPlanningEngagementValuesToRegion]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        engagement: result
      }
    })
  },

  [addPlanningCycleCampaignValuesToRegion]: (
    state,
    { payload: { sectorId: id, campaignId, cycleCampaign: result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`planning-cycle-planning-campaign-${campaignId}`]: result
      }
    })
  },
  [addAmplifyInventoryOosTrendingValuesToRegion]: inventoryReducer.trendingReducer,
  [addAmplifyInventoryOosBrandsValuesToRegion]: inventoryReducer.brandReducer,
  [addAmplifyInventoryOosMaterialValuesToRegion]: inventoryReducer.materialReducer,
  [addAmplifyInventoryOosGeographyValuesToRegion]: inventoryReducer.geographyReducer,
  [addAmplifyInventoryOosFiltersValuesToRegion]: inventoryReducer.filtersReducer,
  [addAmplifyExtraHubTrendingValuesToRegion]: extraHubReducer.trendingReducer,
  [addAmplifyExtraHubEngagementValuesToRegion]: extraHubReducer.engagementReducer,
  [addAmplifyExtraHubFiltersValuesToRegion]: extraHubReducer.filtersReducer,
  [addAmplifyExtraHubGeographyValuesToRegion]: extraHubReducer.geographyReducer,
  [addAmplifyExtraHubActivityValuesToRegion]: extraHubReducer.activityReducer,
  [addAmplifyPriceCaptureComplianceFootprintValuesToRegion]: priceCaptureCompliance.storeFootprint,
  [addAmplifyPriceCaptureComplianceBrandCompletionValuesToRegion]: priceCaptureCompliance.brandCompletion,
  [addAmplifyPriceCaptureComplianceFiltersValuesToRegion]: priceCaptureCompliance.filters,
  [addAmplifyPriceCaptureComplianceGeographyValuesToRegion]: priceCaptureCompliance.geography,
  [addAmplifyPriceComplianceStrategyComplianceValuesToRegion]: priceCaptureCompliance.strategyCompliance,
  [addAmplifyPriceComplianceEdlpComplianceValuesToRegion]: priceCaptureCompliance.edlpCompliance,
  [addAmplifyPriceComplianceGeographyComplianceValuesToRegion]: priceCaptureCompliance.priceComplianceGeography,
  [addAmplifyPriceComplianceGeographyFiltersValuesToRegion]: priceCaptureCompliance.priceComplianceGeographyFilters,
  [addCycleCampaignsToRegion]: addToSectorReducer
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
