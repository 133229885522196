import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

const getProps = (state, props) => props
const getCurrentPriceCaptureCompliance = createSelector(
  dataSourceFromSectorLevel,
  (sectorData) => sectorData?.priceCaptureCompliance || {}
)

const defaultFootprint = {
  IND: {
    storeCount: 0,
    storesUpdated: 0,
    storesRemaining: 0,
    storesPercent: 0
  },
  VS: {
    storeCount: 0,
    storesUpdated: 0,
    storesRemaining: 0,
    storesPercent: 0
  },
  POS: {
    storeCount: 0,
    storesUpdated: 0,
    storesRemaining: 0,
    storesPercent: 0
  }
}
export const getStoreFootprint = createSelector(
  getCurrentPriceCaptureCompliance,
  getProps,
  (price, { productType }) => {
    return Object.assign({}, defaultFootprint, price?.storeFootprint?.[productType] || {})
  }
)

export const getBrandCompletion = createSelector(
  getCurrentPriceCaptureCompliance,
  getProps,
  (price, { productType }) => {
    if (!productType) return {}

    return price[`brandCompletion-${productType}`] || {}
  }
)

export const getFilters = createSelector(getCurrentPriceCaptureCompliance, getProps, (price, { productType }) => {
  return price.filters?.[productType] || {}
})

export const getGeography = createSelector(getCurrentPriceCaptureCompliance, (price) => price.geographies)

export const getStrategyCompliance = createSelector(
  getCurrentPriceCaptureCompliance,
  getProps,
  (price, { productType }) => {
    return price?.[`strategy-compliance-${productType}`] || {}
  }
)

export const getEdlpCompliance = createSelector(getCurrentPriceCaptureCompliance, (price) => price.edlpCompliance || {})

export const getPriceComplianceGeographyFilters = createSelector(
  getCurrentPriceCaptureCompliance,
  getProps,
  (price, { productType }) => {
    const fetchedFilters = price.priceComplianceGeographyFilters?.[productType] || {}

    return fetchedFilters
  }
)

export const getPriceComplianceGeography = createSelector(
  getCurrentPriceCaptureCompliance,
  (price) => price.priceComplianceGeographies
)

export const getNonComplianceForCustomer = createSelector(getCurrentPriceCaptureCompliance, (price) => {
  if (!price?.priceComplianceGeographies) {
    console.error('priceComplianceGeographies is undefined')
    return []
  }

  const customerData =
    price.priceComplianceGeographies[
      Object.keys(price.priceComplianceGeographies).find((key) => key.includes('geography-customer'))
    ] || []

  if (!customerData) {
    console.error('No data found for geography-customer')
    return []
  }
  const strategies = customerData.length ? customerData[0]?.strategies || [] : []

  const notPriceCompliant = Object.values(strategies).filter((strategy) => strategy.notCompliantCustomers === '1')

  return notPriceCompliant
})
