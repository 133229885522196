import uniqBy from 'lodash/uniqBy'

import { addEntities, mergeEntities, setEntities } from 'store/actions'

import { addVolumePerformanceToSellInProgram } from './actions'

const addMergeEntities = (state, { payload }) => {
  if (!payload.sellInPrograms) return state
  return Object.entries(payload.sellInPrograms).reduce((acc, [id, sellInProgram]) => {
    const oldSIP = acc[id] || {}
    const targetsByTerritory = oldSIP.targetsByTerritory || {}
    const newTargetsByTerritory = sellInProgram.targetsByTerritory || {}
    const allTerritories = new Set([...Object.keys(targetsByTerritory), ...Object.keys(newTargetsByTerritory || {})])

    const actualTargets = Array.from(allTerritories).reduce((targets, key) => {
      const oldSipTargets = targetsByTerritory[key] || []
      const newSipTargets = newTargetsByTerritory[key] || []
      return {
        ...targets,
        [key]: Array.from(new Set([...oldSipTargets, ...newSipTargets]))
      }
    }, {})

    return Object.assign(acc, {
      [id]: {
        ...oldSIP,
        ...sellInProgram,
        targetsByTerritory: actualTargets,
        targets: uniqBy((sellInProgram.targets || []).concat(oldSIP.targets || []), 'customerId')
      }
    })
  }, Object.assign({}, state))
}

const ACTION_HANDLERS = {
  [addEntities]: addMergeEntities,
  [setEntities]: (state, action) => action.payload.sellInPrograms || state,
  [mergeEntities]: addMergeEntities,
  [addVolumePerformanceToSellInProgram]: (state, { payload }) => {
    if (!payload.sellInPrograms) return state
    return Object.entries(payload.sellInPrograms).reduce((acc, [id, sellInProgram]) => {
      const oldSIP = acc[id] || {}
      const { targetMatches, totalTargetedVolume } = sellInProgram
      return Object.assign(acc, {
        [id]: {
          ...oldSIP,
          targetMatches,
          totalTargetedVolume
        }
      })
    }, Object.assign({}, state))
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
