import React, { useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func } from 'prop-types'
import tw from 'twin.macro'

import { fetchCustomerSellInPrograms, getCustomer } from 'store/customers/actions'
import { customerSellInPrograms } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import EmptyState from 'components/EmptyState'
import LoadingCard from 'components/LoadingCard'
import { WrappedSpinner } from 'components/Spinner'
import { TouchableTableRow } from 'components/tableRow/TableRow'
import TableRowGroup from 'components/tableRow/TableRowGroup'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const Container = tw.div`w-full h-full p-8`

const CustomerSellInAction = ({ connectedFetchCustomerSellInPrograms, getCustomer }) => {
  const { sectorId: customerId } = useParams()

  const sellInPrograms = useSelector((state) => customerSellInPrograms(state, { customerId }))

  const activeSellInPrograms = useMemo(() => {
    return Object.values(sellInPrograms || {})
  }, [sellInPrograms])

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_SELL_IN_PROGRAMS, { customerId })
  const customerDataKey = createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  useEffect(() => {
    connectedFetchCustomerSellInPrograms(customerId, dataKey)
    getCustomer(customerId, customerDataKey)
  }, [customerId])

  if (isLoading) return <WrappedSpinner icon="spinner" />

  return (
    <Container>
      {activeSellInPrograms.length ? (
        <TableRowGroup heading="Sell-in Programs">
          {activeSellInPrograms.map((program, i) => (
            <TouchableTableRow key={i} link={`${program.id}`}>
              <span>{program.name}</span>
            </TouchableTableRow>
          ))}
        </TableRowGroup>
      ) : (
        <EmptyState title="There is no active Sell In program for this customer" />
      )}
      <LoadingCard dataKey={dataKey} />
    </Container>
  )
}

CustomerSellInAction.propTypes = {
  connectedFetchCustomerSellInPrograms: func,
  getCustomer: func
}

export default connect(null, {
  connectedFetchCustomerSellInPrograms: fetchCustomerSellInPrograms,
  getCustomer
})(CustomerSellInAction)
