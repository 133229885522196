import React, { useContext, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import { AnimatePresence, motion } from 'framer-motion'
import { capitalize } from 'lodash'
import { bool, func, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { requestLogout } from 'store/auth/actions'

import Icon from 'components/Icon'

const menuClasses = 'w-[220px] rounded-md shadow-xl bg-white focus-visible:outline-none z-100'

const menuContentClasses = 'z-[9999] p-2 space-y-2 ring-1 ring-slate-900/10 rounded-md'

const menuItemClasses =
  'text-slate-900 rounded-sm h-9 px-2 flex items-center font-medium gap-2 w-full hover:bg-slate-500/5 focus-visible:bg-slate-500/5 focus-visible:text-slate-900 focus-visible:outline-none radix-disabled:pointer-events-none radix-disabled:text-slate-600/30'

const menuVariants = {
  initial: {
    opacity: 0,
    y: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  },
  enter: {
    opacity: 1,
    y: -8,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  }
}

const Item = ({ label, icon, onHandleClick }) => (
  <RadixDropdownMenu.Item asChild>
    <button className={menuItemClasses} onClick={onHandleClick}>
      <div className="flex opacity-90">
        <Icon icon={icon} small />
      </div>
      <span>{label}</span>
    </button>
  </RadixDropdownMenu.Item>
)

Item.propTypes = {
  label: string.isRequired,
  icon: string.isRequired,
  onHandleClick: func.isRequired
}

const ProfileMenuContent = ({ requestLogout }) => {
  const { translate } = useContext(LangContext)
  const navigate = useNavigate()

  return (
    <RadixDropdownMenu.Content forceMount asChild side="top" align="start">
      <motion.div
        key="menu"
        variants={menuVariants}
        initial="initial"
        animate="enter"
        exit="initial"
        className={menuClasses}
      >
        <div className={menuContentClasses}>
          <Item label={translate('common.settings')} icon="settings" onHandleClick={() => navigate('/settings')} />
          {/* <Item label="Help" icon="question-mark" onHandleClick={() => navigate('about')} /> */}
          <hr className="w-full bg-slate-900/10" />
          <Item
            label={translate('auth.logOut')}
            icon="logout"
            onHandleClick={() => requestLogout({ noRedirect: true })}
          />
        </div>
      </motion.div>
    </RadixDropdownMenu.Content>
  )
}

ProfileMenuContent.propTypes = {
  requestLogout: func.isRequired
}

export const Profile = ({ requestLogout, portal }) => {
  const { translate } = useContext(LangContext)
  const [open, setOpen] = useState(false)

  const user = useSelector((state) => state.auth.user)
  const rolesLabel = [
    { name: 'telesalesRepresentative', label: translate('app.roles.TM') },
    { name: 'accountRepresentative', label: translate('app.roles.accountRep') },
    { name: 'regionalManager', label: translate('app.roles.RSM') },
    { name: 'districtManager', label: translate('app.roles.DM') }
  ]

  const userRole = rolesLabel.find((r) => user.groupCode === r.name)

  return (
    <div className="w-full">
      <RadixDropdownMenu.Root open={open} onOpenChange={setOpen}>
        <RadixDropdownMenu.Trigger asChild>
          <button className="flex w-full select-none items-center space-x-2 rounded-md px-2 py-1 text-left text-white transition hover:bg-white/5 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500 radix-state-open:bg-white/5">
            <Icon icon="person" small />
            <div className="space-y-0.5">
              <p className="font-medium text-white">
                {capitalize(user.firstName)} {capitalize((user.lastName || '')[0])}.
              </p>
              <p className="text-xs text-white/75">{userRole ? userRole.label : translate('app.roles.admin')}</p>
            </div>
          </button>
        </RadixDropdownMenu.Trigger>
        <AnimatePresence>
          {open &&
            (portal ? (
              <RadixDropdownMenu.Portal>
                <ProfileMenuContent requestLogout={() => requestLogout({ noRedirect: true })} />
              </RadixDropdownMenu.Portal>
            ) : (
              <ProfileMenuContent requestLogout={() => requestLogout({ noRedirect: true })} />
            ))}
        </AnimatePresence>
      </RadixDropdownMenu.Root>
    </div>
  )
}

Profile.propTypes = {
  requestLogout: func.isRequired,
  portal: bool
}

export default connect(null, { requestLogout })(Profile)
