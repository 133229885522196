import React, { useContext } from 'react'
import { array, number, object } from 'prop-types'

import LangContext from 'context/LangContext'

import Card from 'components/card'
import CloseRecommendation from 'components/close/CloseRecommendation'
import SuggestedAmounts from 'components/close/SuggestedAmounts'

const VAPEOpportunityCard = ({ span, recommendedQtyByType, productTypeDrivers }) => {
  const { translate } = useContext(LangContext)

  const cardProps = {
    title: translate('common.vape'),
    span,
    recommendedQtyByType
  }

  return (
    <Card {...cardProps}>
      <SuggestedAmounts qty={recommendedQtyByType.totalQty} value={recommendedQtyByType.totalPrice} />
      <div className="flex flex-col gap-2">
        <p className="text-2xs uppercase text-slate-500">{translate('close.recommendations')}</p>
        <div className="flex flex-col gap-3">
          {!productTypeDrivers?.length ? (
            <p className="rounded-md bg-slate-50 p-3 py-6 text-center text-sm text-slate-500">
              {translate('close.recommendations.noDriversFound')}
            </p>
          ) : (
            productTypeDrivers.map((driver) => (
              <CloseRecommendation key={driver.id} type={driver.category} recommendation={driver.message} />
            ))
          )}
        </div>
      </div>
    </Card>
  )
}

VAPEOpportunityCard.propTypes = {
  span: number,
  recommendedQtyByType: object,
  productTypeDrivers: array
}

export default VAPEOpportunityCard
