import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import * as Accordion from '@radix-ui/react-accordion'
import { arrayOf, func, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { getCustomer } from 'store/customers/actions'
import {
  customerPrograms,
  incompleteCustomerSellInPrograms,
  incompleteCustomerSurveys
} from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { getNonComplianceForCustomer } from 'store/priceCaptureCompliance/selectors'
import { pendingCustomerPricing } from 'store/pricings/selectors'

import ObjectiveBadge from 'components/close/ObjectiveBadge'
import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const ActionAccordion = ({ category, categoryColor, summary, items }) => {
  return (
    <Accordion.Item value={category}>
      <Accordion.Trigger className="group flex w-full items-center justify-between gap-2">
        <div className="flex gap-1 max-md:flex-col md:items-center">
          <div className="flex shrink-0 justify-start md:w-36">
            <ObjectiveBadge variant={categoryColor}>{category}</ObjectiveBadge>
          </div>
          <span className="text-left font-medium text-slate-900">{summary}</span>
        </div>
        <div className="relative size-5 shrink-0">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform group-data-[state=open]:rotate-180">
            <Icon icon="down-chevron" compact />
          </div>
        </div>
      </Accordion.Trigger>
      <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
        <ul className="max-h-48 list-disc overflow-y-auto pl-5 text-xs text-slate-900 marker:text-slate-700">
          {items.map((item) => (
            <Link key={item.id} to={item.link}>
              <li>{item.name}</li>
            </Link>
          ))}
        </ul>
      </Accordion.Content>
    </Accordion.Item>
  )
}

ActionAccordion.propTypes = {
  category: string.isRequired,
  categoryColor: string.isRequired,
  summary: string.isRequired,
  items: arrayOf(string).isRequired
}

const Actions = ({ getCustomer }) => {
  const { translate } = useContext(LangContext)
  const { sectorId: customerId } = useParams()

  const [error, setError] = useState()

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId })

  const dataKeyIsLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const activeSellInPrograms = useSelector((state) => incompleteCustomerSellInPrograms(state, { customerId }))
  const pendingPriceCaptures = useSelector((state) => pendingCustomerPricing(state, { customerId }))
  const nonComplianceForCustomer = useSelector((state) => getNonComplianceForCustomer(state))
  const activeSurveys = useSelector((state) => incompleteCustomerSurveys(state, { customerId }))
  const activeGoFundPograms = useSelector((state) => customerPrograms(state, { customerId })).current

  useEffect(() => {
    if (customerId) {
      setError()
      getCustomer(customerId, dataKey).catch((e) => setError(e))
    }
  }, [customerId])

  const ACTIONS = [
    {
      category: translate('app.sellInPrograms'),
      categoryColor: 'sellIn',
      summary: translate('close.objectives.actions.sellIn', { amount: activeSellInPrograms.length }),
      items: activeSellInPrograms.map((program) => ({
        name: program.name,
        link: `/actions/sell-in/${program.id}`
      }))
    },
    {
      category: translate('app.priceCapture'),
      categoryColor: 'priceCheck',
      summary: translate('close.objectives.actions.priceCapture', { amount: pendingPriceCaptures.length }),
      items: pendingPriceCaptures.map((product) => ({
        name: `Price check needed for ${product.shortBrand}`,
        link: `/actions/pricing/${product.upc}`
      }))
    },
    {
      category: translate('app.priceCompliance'),
      categoryColor: 'priceCheck',
      summary: translate('close.objectives.actions.priceCompliance', { amount: nonComplianceForCustomer.length }),
      items: nonComplianceForCustomer.map((product) => ({
        name: product.conditionName || product.benchmarkName || '-',
        link: '/pace/amplify/price-compliance'
      }))
    },
    {
      category: translate('app.surveys'),
      categoryColor: 'survey',
      summary: translate('close.objectives.actions.surveys', { amount: activeSurveys.length }),
      items: activeSurveys.map((program) => ({
        name: program.survey.name,
        link: `/actions/surveys/${program.survey.id}`
      }))
    },
    {
      category: translate('app.extraFunds'),
      categoryColor: 'extraFunds',
      summary: translate('close.objectives.actions.extraFunds', { amount: activeGoFundPograms.length }),
      items: activeGoFundPograms.map((program) => ({
        name: program.goFundBudget,
        link: '/actions/funds'
      }))
    }
  ]

  const emptyState =
    pendingPriceCaptures.length === 0 &&
    nonComplianceForCustomer.length === 0 &&
    activeSurveys.length === 0 &&
    activeGoFundPograms.length === 0

  if (error) return <EmptyState title={getErrorMessage(error)} />

  if (dataKeyIsLoading) return <WrappedSpinner icon="spinner" />

  if (emptyState) return <EmptyState title={translate('close.objectives.actions.noActions')} />

  return (
    <Accordion.Root type="single" collapsible className="flex flex-col gap-4 md:gap-2">
      {ACTIONS.map((action) => {
        if (action.items.length === 0) return null

        return <ActionAccordion key={action.category} {...action} />
      })}
    </Accordion.Root>
  )
}

Actions.propTypes = {
  getCustomer: func
}

export default connect(null, {
  getCustomer
})(Actions)
