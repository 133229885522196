import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { transparentize } from 'polished'
import { func, object, string } from 'prop-types'
import { Pie, PieChart } from 'recharts'
import tw from 'twin.macro'

import GAnalyticsContext from 'context/GAnalyticsContext'
import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchPlanningSellin } from 'store/Sellin/actions'
import { planningSellinData } from 'store/Sellin/selectors'

import Card from 'components/card'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import LoadingCard from 'components/LoadingCard'
import SegmentControl from 'components/SegmentControl'
import { WrappedSpinner } from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import {
  CURRENT_COMPARISONS_OPTIONS,
  DATAKEY_TYPES,
  DISABLE_TOGO_PERIOD,
  PERIOD_FILTERS,
  PLACEHOLDERS,
  UNIT_OF_MEASURE_OPTIONS,
  VAPE_UNIT_OF_MEASURE_OPTIONS
} from 'utils/constants'
import { formatCompactNumber, formatGap, formatNumber, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

function getCircularData(completionPercentage) {
  const completed = completionPercentage
  const toDo = 1 - completed

  return [
    {
      name: 'completionPercentage',
      value: Math.min(completed, 100),
      fill: '#E08330'
    },
    {
      name: 'gapPercentage',
      value: Math.max(toDo, 0),
      fill: transparentize(0.8, '#E08330')
    }
  ]
}

const placeholderCircularData = [
  {
    name: 'completionPercentage',
    value: 0,
    fill: '#E08330'
  },
  {
    name: 'gapPercentage',
    value: 100,
    fill: transparentize(0.8, '#E08330')
  }
]

const Controls = tw.div`grid grid-cols-2 gap-3`

const DataLabel = tw.span`block text-2xs font-medium text-slate-500 text-center`

const ChartWrapper = tw.div`relative flex flex-col items-center justify-center mx-14 h-[200px]`

const DataXL = tw.span`block text-xl font-medium text-slate-900 text-center min-w-fit`

const Data2XL = tw.span`block text-3xl font-medium text-slate-900 text-center`

const SellinCard = ({
  fetchPlanningSellin,
  span,
  amplifyAction,
  vapeCategory = 'all',
  unitOfMeasure: paramUnitofMeasure
}) => {
  const mounted = useRef(false)
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const { startTracking, stopTracking } = useContext(GAnalyticsContext)

  const uomOption =
    currentProductType === 'fmc'
      ? UNIT_OF_MEASURE_OPTIONS.filter(({ value }) => value !== 'pck')
      : VAPE_UNIT_OF_MEASURE_OPTIONS

  const [periodFilter, setPeriodFilter] = useState(PERIOD_FILTERS.TODAY)
  const [currentComparison, setCurrentComparison] = useState(CURRENT_COMPARISONS_OPTIONS[0].value)
  const [fmcUnitOfMeasure, setFmcUnitOfMeasure] = useState('ceq')
  const [vapeUnitOfMeasure, setVapeUnitOfMeasure] = useState('ceq')

  const unitOfMeasure = currentProductType === 'fmc' ? fmcUnitOfMeasure : vapeUnitOfMeasure
  const setUnitOfMeasure = currentProductType === 'fmc' ? setFmcUnitOfMeasure : setVapeUnitOfMeasure
  const [error, setError] = useState()

  useEffect(() => {
    setUnitOfMeasure(paramUnitofMeasure)
  }, [paramUnitofMeasure])

  const customerSellinData = useSelector((state) =>
    planningSellinData(state, {
      period: periodFilter,
      activeProductType: currentProductType,
      vapeCategory,
      unitOfMeasure,
      range: currentComparison
    })
  )

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_SELL_IN, {
    period: periodFilter,
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType,
    vapeCategory,
    unitOfMeasure,
    range: currentComparison
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const periodFilterLabel = useMemo(() => {
    if (periodFilter) return translate(`period.${periodFilter}`)
  }, [periodFilter])

  const translatedPeriods = Object.values(PERIOD_FILTERS).map((p) => ({
    value: p,
    label: translate(`period.${p}`)
  }))

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    const currentKey = createGAKey(periodFilter)
    startTracking(currentKey)
    return () => {
      stopTracking(currentKey)
    }
  }, [periodFilter])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id && mounted) {
      fetchPlanningSellin(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          type: currentProductType,
          vapeCategory,
          unitOfMeasure,
          period: periodFilter,
          range: currentComparison
        },
        dataKey
      ).catch((error) => {
        if (mounted.current) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType, vapeCategory, unitOfMeasure])

  function handleChangePeriod(e) {
    setPeriodFilter(e.target.value)

    if (DISABLE_TOGO_PERIOD.includes(e.target.value)) {
      setCurrentComparison('to_date')
    }
  }

  const renderContent = () => {
    const { actuals, gap, completionPercentage, awr } = customerSellinData

    const circularData = getCircularData(completionPercentage)

    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />

    return (
      <div className="flex h-full grow flex-col justify-center">
        <div className="flex grow items-center justify-around pt-10">
          <div className="my-auto hidden w-16 min-w-fit space-y-5 @lg/card:block">
            <div className="space-y-2">
              <DataLabel>{translate('sellIn.actuals')}</DataLabel>
              <DataXL>
                <Tooltip isNumber hint={actuals}>
                  {actuals ? formatCompactNumber(actuals) : PLACEHOLDERS.NO_VALUE}
                </Tooltip>
              </DataXL>
            </div>
          </div>

          <ChartWrapper>
            <div className="absolute">
              <PieChart width={200} height={200} margin={0}>
                <Pie
                  data={circularData[0] === 0 ? placeholderCircularData : circularData}
                  startAngle={90}
                  endAngle={-270}
                  dataKey="value"
                  innerRadius="90%"
                  outerRadius="100%"
                  fill={['#E08330', transparentize(0.8, '#E08330')]}
                  isAnimationActive={false}
                />
              </PieChart>
            </div>
            <DataLabel>{periodFilterLabel}</DataLabel>
            <Data2XL>{formatPercent(completionPercentage, { convertDecimal: true })}</Data2XL>
            <DataLabel>{translate('sellIn.ofTarget')}</DataLabel>
          </ChartWrapper>

          <div className="my-auto hidden w-16 min-w-fit space-y-5 @lg/card:block">
            <div className="flex flex-col items-center gap-2">
              <DataLabel>{translate('sellIn.gap')}</DataLabel>
              <Tooltip isNumber hint={gap}>
                <DataXL>{formatGap(gap, (val) => formatCompactNumber(val, { shouldStripIfInteger: true }))}</DataXL>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="flex pt-8 @lg/card:hidden">
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="flex flex-col items-center gap-2">
              <DataLabel>{translate('sellIn.actuals')}</DataLabel>
              <Tooltip isNumber hint={actuals}>
                <DataXL>{actuals ? formatCompactNumber(actuals) : PLACEHOLDERS.NO_VALUE}</DataXL>
              </Tooltip>
            </div>
          </div>
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="flex flex-col items-center gap-2">
              <DataLabel>{translate('sellIn.gap')}</DataLabel>
              <Tooltip isNumber hint={gap}>
                <DataXL>{formatGap(gap, (val) => formatCompactNumber(val, { shouldStripIfInteger: true }))}</DataXL>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="flex pt-8 ">
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="flex flex-col items-center gap-2">
              <span className="block text-center text-2xs font-medium text-slate-500">{translate('app.awr4')}</span>
              <Tooltip isNumber hint={formatNumber(awr.awr4)}>
                <span className="block min-w-fit text-center text-xl font-medium text-slate-900">
                  {awr.awr4 ? formatCompactNumber(awr.awr4) : PLACEHOLDERS.NO_VALUE}
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="flex flex-col items-center gap-2">
              <span className="block text-center text-2xs font-medium text-slate-500">{translate('app.awr13')}</span>
              <Tooltip isNumber hint={formatNumber(awr.awr13)}>
                <span className="block min-w-fit text-center text-xl font-medium text-slate-900">
                  {awr.awr13 ? formatCompactNumber(awr.awr13) : PLACEHOLDERS.NO_VALUE}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card title={translate('sellIn.ordersSAT')} span={span} amplifyAction={amplifyAction} amplifySize="sm">
      <div className="flex h-full flex-col">
        <Controls>
          <Dropdown
            onChange={(e) => handleChangePeriod(e)}
            value={periodFilter}
            options={translatedPeriods}
            disabled={isLoading}
          />
          <SegmentControl
            small
            onChange={(e) => setCurrentComparison(e.target.value)}
            value={currentComparison}
            options={CURRENT_COMPARISONS_OPTIONS}
            disabled={DISABLE_TOGO_PERIOD.includes(periodFilter) || isLoading}
          />
          {amplifyAction && (
            <Dropdown
              key="plan-sell-in-unit-of-measure"
              name="unit-of-measure"
              onChange={(e) => setUnitOfMeasure(e.target.value)}
              value={unitOfMeasure}
              options={uomOption}
              shouldUpperCase
            />
          )}
        </Controls>
        {renderContent()}
      </div>
      <LoadingCard dataKey={dataKey} />
    </Card>
  )
}

SellinCard.propTypes = {
  fetchPlanningSellin: func,
  span: object,
  amplifyAction: func,
  vapeCategory: string,
  unitOfMeasure: string
}

const mapActionCreators = {
  fetchPlanningSellin
}

export default connect(null, mapActionCreators)(SellinCard)

function createGAKey(currentValue) {
  return `amplify_sellin_som_filter_${currentValue}`
}
