import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import LangContext from 'context/LangContext'

import { getCustomerReminders } from 'store/customers/actions'
import { pendingCustomerReminders } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import List from './List'

const Reminders = ({ getCustomerReminders }) => {
  const { sectorId: customerId, sectorType } = useParams()
  const { translate } = useContext(LangContext)

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_REMINDERS, { customerId })
  const dataKeyIsLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const customerReminders = useSelector((state) => pendingCustomerReminders(state, { customerId }))

  const [error, setError] = useState()

  useEffect(() => {
    if (sectorType === SECTOR_LEVELS.CUSTOMER && customerId) {
      setError()
      getCustomerReminders({ customerId, dataKey }).catch((error) => setError(getErrorMessage(error)))
    }
  }, [customerId, sectorType, dataKey])

  if (error) return <EmptyState title={getErrorMessage(error)} />

  if (dataKeyIsLoading) return <WrappedSpinner icon="spinner" />

  const listOfCustomerReminders = customerReminders.map((reminder) => ({
    text: reminder.content,
    done: reminder.checked,
    linkTo: '/pace/evaluate'
  }))

  if (listOfCustomerReminders.length < 1)
    return <EmptyState title={translate('close.objectives.actions.noReminders')} />

  return <List items={listOfCustomerReminders} />
}

Reminders.propTypes = {
  getCustomerReminders: PropTypes.func.isRequired
}

export default connect(null, {
  getCustomerReminders
})(Reminders)
