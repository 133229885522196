import React, { createContext, useState } from 'react'
import { node } from 'prop-types'

import { DISTRO_VAPE_CHANNEL } from 'utils/constants'

const DistroContext = createContext()
export default DistroContext

export const DistroProvider = ({ children }) => {
  const [period, setPeriod] = useState('l26')
  const [vapeCategory, setVapeCategory] = useState('all')
  const [vapeChannel, setVapeChannel] = useState(DISTRO_VAPE_CHANNEL[0].value)

  const value = {
    vapeCategory,
    vapeChannel,
    period,
    setVapeCategory,
    setVapeChannel,
    setPeriod
  }

  return <DistroContext.Provider value={value}>{children}</DistroContext.Provider>
}

DistroProvider.propTypes = {
  children: node.isRequired
}
