import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS } from 'store/actions'

import { validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchStoreFootprint = createAction(
  'Fetch Price Capture Compliance Store Footprint Data',
  ({ dataKey, sectorType, sectorId, productType }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data } = await api.getStoreFootprint(sectorType, sectorId, productType)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].priceCaptureCompliance?.storeFootprint
        dispatch(
          action({
            id: sectorId,
            productType,
            ...data
          })
        )
      })
)

export const fetchBrandCompletion = createAction(
  'Fetch Price Capture Compliance Brand Completion Data',
  ({ dataKey, sectorType, sectorId, productType }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { brandCompletion }
        } = await api.getBrandCompletion(sectorType, sectorId, productType)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].priceCaptureCompliance?.brandCompletion

        dispatch(
          action({
            id: sectorId,
            brandCompletion,
            productType
          })
        )
      })
)

export const fetchFilters = createAction(
  'Fetch Price Capture Compliance Filters Data',
  ({ dataKey, sectorType, sectorId, productType }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data: filters } = await api.getFilters(sectorType, sectorId, productType)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].priceCaptureCompliance?.filters
        dispatch(
          action({
            productType,
            id: sectorId,
            filters
          })
        )
      })
)

export const fetchGeography = createAction(
  'Fetch Price Capture Compliance Geography Data',
  ({
      dataKey,
      sectorType,
      sectorId,
      geography,
      productType,
      offset,
      limit,
      filters: { channel, ownershipType, banner, headoffice, tier, material },
      sortBy,
      sortDirection
    }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { geographies }
        } = await api.getGeography(
          sectorType,
          sectorId,
          geography,
          productType,
          offset,
          limit,
          {
            channel,
            ownershipType,
            banner,
            headoffice,
            tier,
            material
          },
          sortBy,
          sortDirection
        )

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.geography
        dispatch(
          action({
            id: sectorId,
            geography,
            productType,
            offset,
            filters: {
              channel,
              ownershipType,
              banner,
              headoffice,
              tier,
              material
            },
            data: geographies,
            sortBy,
            sortDirection
          })
        )
      })
)

export const fetchStrategyCompliance = createAction(
  'Fetch Price Compliance Strategy Compliance Data',
  ({ dataKey, sectorType, sectorId, productType }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { strategyCompliance }
        } = await api.getStrategyCompliance(sectorType, sectorId, productType)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.strategyCompliance
        dispatch(
          action({
            id: sectorId,
            strategyCompliance,
            productType
          })
        )
      })
)

export const fetchEdlpCompliance = createAction(
  'Fetch Price Compliance Edlp Compliance Data',
  ({ dataKey, sectorType, sectorId }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { edlpCompliance }
        } = await api.getEdlpCompliance(sectorType, sectorId)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.edlpCompliance
        dispatch(
          action({
            id: sectorId,
            edlpCompliance
          })
        )
      })
)

export const fetchPriceComplianceGeographyFilters = createAction(
  'Fetch Price Compliance Geography Filters Data',
  ({ dataKey, sectorType, sectorId, productType }) =>
    (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data: filters } = await api.getPriceComplianceGeographyFilters(sectorType, sectorId, productType)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.priceComplianceGeographyFilters
        dispatch(
          action({
            id: sectorId,
            filters,
            productType
          })
        )
      })
)

export const fetchPriceComplianceGeographyCompliance = createAction(
  'Fetch Price Compliance Geography Compliance Data',
  ({
      dataKey,
      sectorType,
      sectorId,
      geography,
      productType,
      // Below are filters
      filters,
      offset,
      limit,
      sortBy,
      direction
    }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { geographies }
        } = await api.getPriceComplianceGeography(
          sectorType,
          sectorId,
          geography,
          productType,
          {
            ...filters,
            offset,
            limit
          },
          sortBy,
          direction
        )

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.priceComplianceGeography

        dispatch(
          action({
            id: sectorId,
            geography,
            productType,
            data: geographies,
            filters: {
              ...filters,
              offset,
              limit
            },
            sortBy,
            direction
          })
        )
      })
)
