import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import config from 'config'
import isEmpty from 'lodash/isEmpty'
import { bool, object, string } from 'prop-types'
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import theme from 'tailwindcss/colors'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { amplifySelloutSOMData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataLegend from 'components/DataTable/DataLegend'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import {
  formatCompactCurrency,
  formatCompactNumber,
  formatCurrency,
  formatNumber,
  formatPercent
} from 'utils/formatters'
import { sortDataTableBy } from 'utils/helpers'

const CardToolTip = tw(Tooltip)`h-full flex`

const generateRows = (data, dataFormatter, tooltipFormatter) => {
  if (!data) return []

  return data.map((row) => ({
    type: <DataLegend color={row.fill} name={row.name} />,
    mainPeriod: (
      <CardToolTip isNumber hint={tooltipFormatter(row.mainPeriod)}>
        {dataFormatter(row.mainPeriod)}
      </CardToolTip>
    ),
    variation: (
      <CardToolTip isNumber hint={tooltipFormatter(row.variation)}>
        {row.variation ? <DataVariation variation={row.variation} formatter={dataFormatter} /> : '-'}
      </CardToolTip>
    ),
    awr4: (
      <CardToolTip isNumber hint={tooltipFormatter(row.awr4)}>
        {dataFormatter(row.awr4)}
      </CardToolTip>
    )
  }))
}

const AmplifySelloutSOMCard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  dataType,
  unitOfMeasure,
  selloutDataLoading
}) => {
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const { translate } = useContext(LangContext)

  const [sortBy, setSortBy] = useState({ column: 'mainPeriod', order: 'desc' })
  const setSorted = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
  }

  const typeColumnHeader = currentProductType === 'fmc' ? 'Manufacturer' : 'Brand'

  const disableAWR = !config.featureFlags.awr || (currentProportion === 'share' && currentTimeDisplay === 'rolling')

  const commonColumns = [
    {
      field: 'type',
      headerName: typeColumnHeader
    },
    !disableAWR && {
      field: 'awr4',
      headerName: 'AWR 4'
    }
  ]

  const COLS = {
    rolling: [
      ...commonColumns,
      {
        field: 'mainPeriod',
        headerName: 'L4'
      },
      {
        field: 'variation',
        headerName: 'vs. P4'
      }
    ],
    pointInTime: [
      ...commonColumns,
      {
        field: 'mainPeriod',
        headerName: 'CTD'
      },
      {
        field: 'variation',
        headerName: 'vs. PC'
      }
    ]
  }
  const selloutData = useSelector((state) =>
    amplifySelloutSOMData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentChannel,
      currentVapeCategory,
      dataType,
      unitOfMeasure,
      columnSorted: sortBy.column,
      sortDirection: sortBy.order
    })
  )

  const dataFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: '-' })
      : currentMetric === 'cost'
      ? (v) => formatCompactCurrency(v, { nullDisplay: '-' })
      : (v) => formatCompactNumber(v, { nullDisplay: '-' })
  const tooltipFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: null })
      : currentMetric === 'cost'
      ? (v) => formatCurrency(v, { nullDisplay: null })
      : (v) => formatNumber(v, { nullDisplay: null })
  const dataFormat = currentProportion === 'share' ? 'percent' : dataType
  const yAxisFormatter =
    dataFormat === 'percent' ? (v) => formatPercent(v, { convertDecimal: true }) : formatCompactNumber

  const getContent = () => {
    if (selloutDataLoading) {
      return <WrappedSpinner icon="spinner" />
    }

    if (isEmpty(selloutData)) {
      return <EmptyState title={translate('app.warn.noData')} />
    }

    return (
      <div className="flex h-full max-h-[490px] flex-col justify-between">
        <div className="h-full max-h-[192px] w-full lg:max-h-[256px]">
          <ResponsiveContainer height="99%" width="99%">
            <BarChart data={selloutData} margin={{ bottom: 0, left: 0, right: 0, top: 15 }}>
              {/* <CartesianGrid stroke={theme.slate[300]} vertical={false} /> */}
              <XAxis
                style={{
                  fontSize: '12px'
                }}
                axisLine={false}
                dataKey="name"
                dy={10}
                stroke={theme.slate[500]}
                tickLine={false}
              />
              <YAxis
                hide
                // interval="preserveStartEnd"
                // style={{
                //   fontSize: '12px'
                // }}
                axisLine={false}
                // dx={-8}
                // // stroke={theme.slate[500]}
                tickLine={false}
                // tickFormatter={yAxisFormatter}
              />
              <Bar dataKey="mainPeriod" fill="#8884d8" isAnimationActive={false} minPointSize={1}>
                <LabelList dataKey="mainPeriod" position="top" formatter={yAxisFormatter} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="mt-6 h-full overflow-y-auto">
          <DataTable
            onColumnClick={setSorted}
            activeColumn={sortBy}
            unClickableColumns={['type']}
            columns={COLS[currentTimeDisplay]}
            rows={generateRows(selloutData, dataFormatter, tooltipFormatter)}
            fullWidth
          />
        </div>
      </div>
    )
  }

  return (
    <Card title={currentProductType === 'fmc' ? 'Manufacturer' : 'Brand'} span={span} displayAmplify={false}>
      {getContent()}
    </Card>
  )
}

AmplifySelloutSOMCard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  unitOfMeasure: string,
  selloutDataLoading: bool
}

export default AmplifySelloutSOMCard
