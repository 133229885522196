import React, { useContext } from 'react'
import { bool, func, node, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import FabButton from 'components/button/FabButton'
import Icon from 'components/Icon'
import { Modal } from 'components/Modal'

import Actions from './Actions'
import Reminders from './Reminders'
import SectionCard from './SectionCard'

const Section = ({ title, icon, children }) => {
  return (
    <div className="flex max-w-full flex-col gap-3">
      <div className="flex items-center justify-center gap-1.5 rounded-md bg-brand-50 p-2 text-slate-500">
        <Icon icon={icon} compact />
        <h3 className="text-sm font-medium uppercase ">{title}</h3>
      </div>
      <div className="flex flex-col gap-3 text-sm">{children}</div>
    </div>
  )
}

Section.propTypes = {
  title: string.isRequired,
  icon: string.isRequired,
  children: node.isRequired
}

const OpportunitiesModal = ({ open, onOpenChange }) => {
  const { translate } = useContext(LangContext)

  return (
    <Modal
      size="xLarge"
      trigger={<FabButton icon="list" />}
      footer={
        <div className="flex w-full justify-end">
          <Button primary icon="checkmark" onClick={() => onOpenChange(false)}>
            {translate('common.okay')}
          </Button>
        </div>
      }
      title={translate('close.objectives.title')}
      open={open}
      onOpenChange={onOpenChange}
    >
      <div className="grid max-w-full gap-x-5 gap-y-8 md:grid-cols-2">
        <div className="min-w-0 max-w-full md:col-span-2">
          <Section title="Performance Soundbites" icon="chart">
            <div className="grid max-w-full gap-5 md:grid-cols-2">
              <SectionCard
                title="Race to Cycle Target"
                tip="Next order days by end of cycle: 9/10/2024, 9/17/2024, 9/24/2024"
                data={{
                  fmc: { summary: '-600 ctns to reach cycle goal' },
                  vape: { summary: '-350 units to reach cycle goal' }
                }}
                amplifyLink="/pace/amplify/sell-in"
              />
              <SectionCard
                title="Spotting Hidden Opportunities"
                tip="Seize the distribution gap! Close the missing products in FMC and Vape to maximize performance"
                data={{
                  fmc: { summary: '4 SKUs are not available on the shelves' },
                  vape: { summary: '5 SKUs are not available on the shelves' }
                }}
                amplifyLink="/pace/amplify/distro"
              />
              <SectionCard
                title="Unlock the Sell-Out Surge"
                data={{
                  fmc: {
                    summary:
                      'The share of Volume Dropped by -1.06 pp this week, not at 44.95%. We need a targeted push to regain momentum in key segments!'
                  },
                  vape: {
                    summary:
                      'The share of Volume Dropped by -1.06 pp this week, not at 44.95%. We need a targeted push to regain momentum in key segments!'
                  }
                }}
                amplifyLink="/pace/amplify/sell-out"
              />
              <SectionCard
                title="Fine Tune Pricing Opportunities"
                tip="Fine-tune your pricing for big sell-out gains! Stay competitive and aligned with BAT strategies! Check the top 5 products with the largest pricing gaps vs. their lowest peer prices below!"
                data={{
                  fmc: { summary: undefined },
                  vape: { summary: undefined }
                }}
                amplifyLink="/pace/amplify/price-capture"
              />
            </div>
          </Section>
        </div>
        <Section title={translate('common.actions')} icon="actions">
          <Actions />
        </Section>
        <Section title={translate('common.reminders')} icon="notification">
          <Reminders />
        </Section>
      </div>
    </Modal>
  )
}

OpportunitiesModal.propTypes = {
  open: bool,
  onOpenChange: func
}

export default OpportunitiesModal
