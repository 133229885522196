import { setEntities } from 'store/actions'

import { mergeSearchResult, setCurrentSector, setProductType, setSelectedLevel } from './actions'

const ACTION_HANDLERS = {
  [setEntities]: (state, action) => action.payload.sector || state,
  [setCurrentSector]: (state, action) =>
    Object.assign({}, state, {
      ...state,
      currentSector: action.payload
    }),
  [setSelectedLevel]: (state, action) =>
    Object.assign({}, state, {
      ...state,
      selectedLevel: action.payload
    }),
  [setProductType]: (state, action) =>
    Object.assign({}, state, {
      ...state,
      productType: action.payload
    }),
  [mergeSearchResult]: (state, { payload: { data, sectorType } }) =>
    Object.assign({}, state, {
      ...state,
      results: {
        ...state.results,
        [sectorType]: data.sectors
      }
    })
}

const initialState = {}
export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
