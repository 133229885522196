import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import { arrayOf, number, object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'

const List = ({ items, title, maxItems = 5 }) => {
  const { translate } = useContext(LangContext)

  const [isViewAll, setIsViewAll] = useState(false)

  const listItems = isViewAll ? items : items.slice(0, maxItems)

  return (
    <div className="flex flex-col gap-2">
      {title && <h4 className="text-base font-medium text-slate-900">{title}</h4>}
      <div className="flex flex-col gap-2">
        <ul className="flex list-disc flex-col gap-1.5 pl-4 text-slate-900 *:leading-snug marker:text-slate-700">
          {listItems.map((item, index) => (
            <Link key={index} to={item.linkTo}>
              <li className={item.done && 'text-decoration-line: line-through'}>{capitalize(item.text)}</li>
            </Link>
          ))}
        </ul>
        {items.length > maxItems && (
          <div className="pl-4">
            <Button tertiary onClick={() => setIsViewAll(!isViewAll)}>
              {isViewAll ? translate('common.viewLess') : translate('common.viewAll')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

List.propTypes = {
  items: arrayOf(object).isRequired,
  maxItems: number,
  title: string
}

export default List
