import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Select, { createFilter } from 'react-select'
import { mix, rgba, stripUnit } from 'polished'
import { bool, number, object, string } from 'prop-types'
import styled from 'styled-components'

import * as customerSelector from 'store/customers/selectors'
import * as territorySelector from 'store/territories/selectors'

import { black, borderColor, grey, offWhite, red, secondaryColor } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import { smallFontSize } from 'styles/typography'

const StyledSuggest = styled(Select)`
  input[type='text']:focus {
    box-shadow: none;
  }

  position: relative;

  .multi-select__control {
    border-radius: ${borderRadius};
    border-color: ${borderColor};
    transition: border-color ${animationTime} ${animationCurve};

    &:hover {
      border-color: ${mix(0.1, black, borderColor)};
    }

    &--is-focused {
      border-color: ${secondaryColor};

      &:hover {
        border-color: ${secondaryColor};
      }
    }
  }

  .multi-select__multi-value {
    color: ${grey};
    background-color: ${offWhite};
    font-size: ${smallFontSize};
    border-radius: ${stripUnit(borderRadius) - 2 + 'px'};
  }

  .multi-select__multi-value__remove {
    cursor: pointer;
    color: ${grey};
    transition: all ${animationTime} ${animationCurve};

    &:hover {
      background-color: ${rgba(red, 0.15)};
      color: ${red};
    }
  }
`
const searchFilter = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: (option) => {
    return `${option.data.name} ${option.data.id} ${option.data.address} ${option.data.banner?.name}`
  },
  trim: true
})

const OptionLabel = ({ id, name, address }) => {
  return (
    <span>
      {name} <small>({id})</small>
      {address && (
        <>
          <br />
          <small>{address.line1 || address.line_1}</small>
        </>
      )}
    </span>
  )
}

OptionLabel.propTypes = {
  id: number,
  name: string,
  address: string
}

const ErpSuggest = ({ input = {}, isMulti = true, notTerritoryRestricted, ...props }) => {
  const currentTerritoryId = useSelector((state) => state.auth.currentTerritoryId)
  const erpOptions = useSelector((state) =>
    notTerritoryRestricted ? customerSelector.erpOptions(state) : territorySelector.territoryErpOptions(state)
  )

  const [searchInput, setInput] = useState('')
  return (
    <StyledSuggest
      {...props}
      currentTerritoryId={currentTerritoryId}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 12 })
      }}
      inputId="erp-suggest"
      inputValue={searchInput}
      onInputChange={(value, action) => {
        if (['input-change', 'menu-close'].includes(action.action)) setInput(value)
      }}
      isClearable={Boolean(input.value)}
      cacheOptions
      className="erp-select"
      classNamePrefix="erp-select"
      getOptionLabel={OptionLabel}
      getOptionValue={({ id }) => id}
      placeholder="Search name, ERP or banner..."
      menuPlacement="top"
      options={erpOptions}
      isMulti={isMulti}
      filterOption={searchFilter}
      closeMenuOnSelect={!isMulti}
      blurInputOnSelect={!isMulti}
      name={input.name}
      onBlur={() => input.onBlur?.()}
      onChange={(val) => {
        input.onChange(val)
      }}
      value={input.value || []}
      menuPosition="fixed"
    />
  )
}

ErpSuggest.propTypes = {
  input: object,
  isMulti: bool,
  notTerritoryRestricted: bool
}

export default ErpSuggest
