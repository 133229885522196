import React from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import capitalize from 'lodash/capitalize'
import PropTypes from 'prop-types'

import Checkbox from 'components/Checkbox'
import Icon from 'components/Icon'

// handleCheck returns array
const Checklist = ({ header, items, handleCheck }) => {
  const isHeaderChecked = items.every((item) => item.checked)

  const handleHeaderCheck = () => {
    handleCheck(items.map((item) => ({ id: item.id, checked: !isHeaderChecked })))
  }

  return (
    <div className="flex flex-col">
      <div className="flex w-full justify-between">
        <Accordion.Root collapsible className="w-full" defaultValue={header}>
          <Accordion.Item value={header}>
            <Accordion.Trigger className="group flex items-center gap-2">
              <div className="flex size-6 items-center justify-center">
                <Icon icon="down-chevron" className="transition-transform group-data-[state=open]:-rotate-180" />
              </div>
              <h4 className="text-left text-base font-medium leading-snug">{header}</h4>
            </Accordion.Trigger>
            <Accordion.Content className="group w-[calc(100%+30px)] overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
              <div className="flex max-h-40 flex-col gap-2 overflow-y-auto pl-8 ">
                {items.map(({ id, content, checked }) => {
                  return (
                    <div
                      key={id}
                      className="flex max-w-full items-center justify-between gap-2 transition-opacity duration-150 first:pt-3 group-data-[state=closed]:opacity-0 group-data-[state=open]:opacity-100"
                    >
                      <span className="text-slate-600">{capitalize(content)}</span>
                      <Checkbox checked={checked} onClick={() => handleCheck([{ id, checked: !checked }])} />
                    </div>
                  )
                })}
              </div>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>

        <div className="py-1.5">
          <Checkbox checked={isHeaderChecked} onClick={() => handleHeaderCheck()} />
        </div>
      </div>
    </div>
  )
}

Checklist.propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      reminder: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired
    })
  ).isRequired,
  handleCheck: PropTypes.func.isRequired
}

export default Checklist
