import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import config from 'config'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import { bool, object, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { amplifySelloutBrandPerfData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import {
  formatCompactCurrency,
  formatCompactNumber,
  formatCurrency,
  formatNumber,
  formatPercent
} from 'utils/formatters'
import { sortDataTableBy } from 'utils/helpers'

const FIRST_COL = {
  vape: {
    field: 'name',
    headerName: 'Brand Variant'
  },
  fmc: {
    field: 'name',
    headerName: 'Brand'
  },
  nrt: {
    field: 'name',
    headerName: 'Brand'
  }
}

const CardToolTip = tw(Tooltip)`h-full flex`

function generateRows(data, dataFormatter, tooltipFormatter) {
  if (!data) return []

  return data.map(({ name, mainPeriod, variation, awr4 }) => ({
    name,
    mainPeriod: (
      <CardToolTip isNumber hint={tooltipFormatter(mainPeriod)}>
        {dataFormatter(mainPeriod)}
      </CardToolTip>
    ),
    variation: (
      <CardToolTip isNumber hint={tooltipFormatter(variation)}>
        {isNull(variation) ? '-' : <DataVariation variation={variation} formatter={dataFormatter} />}
      </CardToolTip>
    ),
    awr4: (
      <CardToolTip isNumber hint={tooltipFormatter(awr4)}>
        {dataFormatter(awr4)}
      </CardToolTip>
    )
  }))
}

const AmplifySelloutBrandCard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  dataType,
  unitOfMeasure,
  selloutDataLoading
}) => {
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const { translate } = useContext(LangContext)

  const [sortBy, setSortBy] = useState({ column: 'mainPeriod', order: 'desc' })
  const setSorted = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
  }

  const disableAWR = !config.featureFlags.awr || currentProportion === 'share'

  const TIME_COLS = {
    rolling: [
      !disableAWR && {
        field: 'awr4',
        headerName: 'AWR 4'
      },
      {
        field: 'mainPeriod',
        headerName: 'L4'
      },
      {
        field: 'variation',
        headerName: 'vs. P4'
      }
    ],
    pointInTime: [
      {
        field: 'awr4',
        headerName: 'AWR 4'
      },
      {
        field: 'mainPeriod',
        headerName: 'CTD'
      },
      {
        field: 'variation',
        headerName: 'vs. PC'
      }
    ]
  }

  const cols = [FIRST_COL[currentProductType]].concat(TIME_COLS[currentTimeDisplay])
  const selloutData = useSelector((state) =>
    amplifySelloutBrandPerfData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentChannel,
      currentVapeCategory,
      dataType,
      unitOfMeasure,
      columnSorted: sortBy.column,
      sortDirection: sortBy.order
    })
  )

  const dataFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: '-' })
      : currentMetric === 'cost'
      ? (v) => formatCompactCurrency(v, { nullDisplay: '-' })
      : (v) => formatCompactNumber(v, { nullDisplay: '-' })
  const tooltipFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: null })
      : currentMetric === 'cost'
      ? (v) => formatCurrency(v, { nullDisplay: null })
      : (v) => formatNumber(v, { nullDisplay: null })
  const cardProps = {
    title: currentProductType === 'fmc' ? 'Brand' : 'Brand Variant',
    span,
    displayAmplify: false
  }

  if (selloutDataLoading) {
    return (
      <Card {...cardProps}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  if (isEmpty(selloutData)) {
    return (
      <Card {...cardProps}>
        <EmptyState title={translate('app.warn.noData')} />
      </Card>
    )
  }
  return (
    <Card {...cardProps}>
      <div className="max-h-[490px] overflow-y-auto">
        <DataTable
          onColumnClick={setSorted}
          activeColumn={sortBy}
          unClickableColumns={['name']}
          columns={cols}
          rows={generateRows(selloutData, dataFormatter, tooltipFormatter)}
          fillContainer
        />
      </div>
    </Card>
  )
}

AmplifySelloutBrandCard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  unitOfMeasure: string,
  selloutDataLoading: bool
}

export default AmplifySelloutBrandCard
