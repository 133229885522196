import React from 'react'
import { stripUnit } from 'polished'
import { bool, func, object, string } from 'prop-types'
import styled, { css } from 'styled-components'

import Icon from 'components/Icon'

import { getInputFieldProps, getMeta } from 'utils/helpers'

import { backgroundColor, borderColor, primaryColor, red, white } from 'styles/colors'
import { animationCurve, animationTimeFast, borderRadius, square } from 'styles/global'
import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

const Container = styled.label`
  display: inline-flex;
  cursor: pointer;

  ${(props) =>
    props.ignoreClick &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`

const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`

const Control = styled.span`
  display: flex;
  margin-right: ${stripUnit(spacing.small) * 1.5 + 'px'};
  flex-shrink: 0;
`

const Indicator = styled.div`
  ${square`18px`};
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: ${stripUnit(borderRadius) * 0.5 + 'px'};
  flex-shrink: 0;
  background-color: ${(props) => (props.disabled ? backgroundColor : white)};
  border: 1px solid ${borderColor};
  transition: background-color ${animationTimeFast} ${animationCurve},
    border-color ${animationTimeFast} ${animationCurve};

  ${Input}:checked + & {
    background-color: ${primaryColor};
    border-color: ${primaryColor};
  }
`

const Check = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) scale(0.6);
  transition: opacity ${animationTimeFast} ${animationCurve}, transform ${animationTimeFast} ${animationCurve};

  ${Input}:checked ~ ${Indicator} & {
    opacity: 1; /* Show icon when checkbox is checked */
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
`

const Label = styled.span`
  text-align: left;
  margin-top: -3px; /* Align text with checkbox */
`
const ErrorMessage = styled.small`
  color: ${red};
  display: block;
  margin-top: ${spacing.small};
  font-size: ${tinyFontSize};
`

const Checkbox = ({ ignoreClick, label, small, checked, onClick, ...rest }) => {
  const inputFieldProps = getInputFieldProps(rest)
  const meta = getMeta(rest)

  return (
    <>
      <Container ignoreClick={ignoreClick || rest.disabled}>
        <Control small={small}>
          <Input
            checked={checked !== undefined ? checked : inputFieldProps.value}
            onChange={onClick}
            {...inputFieldProps}
            {...rest}
          />
          <Indicator disabled={rest.disabled}>
            <Check icon="checkmark-small" white />
          </Indicator>
        </Control>
        {label && <Label small={small}>{label}</Label>}
      </Container>
      {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
    </>
  )
}
Checkbox.propTypes = {
  label: string,
  checked: bool,
  onClick: func,
  small: bool,
  input: object,
  meta: object,
  ignoreClick: bool
}

export default Checkbox
