import React from 'react'
import { string } from 'prop-types'

import Icon from 'components/Icon'

import { accents } from '../../styles/colors'

const CloseRecommendation = ({ type, recommendation }) => {
  const propertiesByType = {
    OOS: { color: accents.lime, icon: 'shelves' },
    trend_correction: { color: accents.lightblue, icon: 'bar-chart-4-bars' },
    distribution: { color: accents.magenta, icon: 'package' },
    running_inventory: { color: accents.orange, icon: 'quick-reorder' },
    cycle_plan: { color: accents.green, icon: 'calendar-month' },
    soq_gap: { color: accents.purple, icon: 'stack-line-chart' },
    other: { color: accents.amber, icon: 'trending-up' }
  }

  const { color, icon } = propertiesByType[type] || propertiesByType.other

  return (
    <div className="flex gap-3 rounded-md bg-slate-50 p-4">
      <div className="shrink-0" style={{ color }}>
        <Icon icon={icon} medium />
      </div>
      <p className="font-medium text-slate-700">{recommendation}</p>
    </div>
  )
}

CloseRecommendation.propTypes = {
  type: string,
  recommendation: string.isRequired
}

export default CloseRecommendation
