import React, { useContext } from 'react'
import { number } from 'prop-types'

import LangContext from 'context/LangContext'

import { formatCurrency } from 'utils/formatters'

const SuggestedAmounts = ({ qty, value }) => {
  const { translate } = useContext(LangContext)
  return (
    <div className="flex flex-col gap-2 font-medium">
      <p className="text-2xs uppercase text-slate-500 ">{translate('close.recommendations.suggestedAmounts')}</p>
      <div className="grid grid-cols-2 gap-2 rounded-md p-4 text-sky-600 ring-1 ring-slate-900/10 @container">
        <p className="col-span-2 text-center text-lg @[18rem]:col-span-1">{qty} ctns</p>
        <p className="col-span-2 text-center text-lg @[18rem]:col-span-1">{formatCurrency(value)}</p>
      </div>
    </div>
  )
}

SuggestedAmounts.propTypes = {
  qty: number.isRequired,
  value: number.isRequired
}

export default SuggestedAmounts
