import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import LangContext from 'context/LangContext'

import { getCustomerReminders, updateCustomerReminders } from 'store/customers/actions'
import { pendingCustomerReminders } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import Button from 'components/button/Button'
import EmptyState from 'components/EmptyState'
import Checklist from 'components/evaluate/Checklist'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import RemindersModal from './RemindersModal'

const Reminders = ({ getCustomerReminders, updateCustomerReminders }) => {
  const { sectorId: customerId, sectorType } = useParams()
  const { translate } = useContext(LangContext)

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_REMINDERS, { customerId })
  const dataKeyIsLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const customerReminders = useSelector((state) => pendingCustomerReminders(state, { customerId }))

  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false)
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [pendingChanges, setPendingChanges] = useState([])

  useEffect(() => {
    if (sectorType === SECTOR_LEVELS.CUSTOMER && customerId) {
      setError()
      getCustomerReminders({ customerId, dataKey }).catch((error) => setError(getErrorMessage(error)))
    }
  }, [customerId, sectorType, dataKey])

  const handleRemindersCheck = (reminders) => {
    const newPendingChanges = reminders.reduce((acc, reminder) => {
      const { id, checked } = reminder

      const originalReminder = customerReminders.find((r) => r.id === id)
      if (!originalReminder) return acc

      const pendingChange = pendingChanges.find((r) => r.id === id)

      if (pendingChange) {
        const hasRevertedToOriginal = originalReminder.checked === checked
        if (hasRevertedToOriginal) {
          return acc.filter((r) => r.id !== id)
        }
      } else if (originalReminder.checked === checked) {
        return acc
      }

      return [...acc, { id, checked }]
    }, pendingChanges)

    setPendingChanges(newPendingChanges)
  }

  const saveChanges = async () => {
    setIsLoading(true)
    setError()

    await updateCustomerReminders({ customerId, reminders: pendingChanges })
      .then(() => setPendingChanges([]))
      .catch((error) => setError(getErrorMessage(error)))
      .finally(() => setIsLoading(false))
  }

  const getContent = () => {
    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (dataKeyIsLoading) return <WrappedSpinner icon="spinner" />

    const currentReminders = customerReminders.map((reminder) => {
      const isPending = pendingChanges.find((change) => change.id === reminder.id)
      if (isPending) return { ...reminder, checked: isPending.checked }
      return reminder
    })

    return (
      <>
        <div className="flex flex-col gap-3">
          {customerReminders.length ? (
            <Checklist
              header={translate('evaluate.objectives.remindersList.title')}
              items={currentReminders}
              handleCheck={handleRemindersCheck}
            />
          ) : (
            'No reminders at this time.'
          )}
        </div>

        <div className="flex items-center justify-between">
          <Button onClick={() => setIsReminderModalOpen(true)} primary>
            {translate('common.newReminder')}
          </Button>
        </div>
        <RemindersModal isReminderModalOpen={isReminderModalOpen} setIsReminderModalOpen={setIsReminderModalOpen} />
      </>
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium">{translate('evaluate.objectives.reminders')}</h3>
        <div>
          <Button disabled={!pendingChanges.length || isLoading} isLoading={isLoading} onClick={saveChanges} secondary>
            {translate('common.saveChanges')}
          </Button>
        </div>
      </div>
      {getContent()}
    </div>
  )
}

Reminders.propTypes = {
  getCustomerReminders: PropTypes.func.isRequired,
  updateCustomerReminders: PropTypes.func.isRequired
}

export default connect(null, {
  getCustomerReminders,
  updateCustomerReminders
})(Reminders)
