import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import pickBy from 'lodash/pickBy'
import moment from 'moment'
import { bool, func, object, string } from 'prop-types'
import styled from 'styled-components'

import { submitCustomerProgramUpdate, submitCustomerSellInException } from 'store/sellInPrograms/actions'

import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'
import SegmentControl from 'components/SegmentControl'
import SkuTable from 'components/sellInPrograms/SkuTable'

import { AGREEMENT_TERMS, STATUS } from 'utils/constants'
import { required } from 'utils/validators'

import Questions from './RenderSellInQuestions'

const LastUpdated = ({ className, timestamp }) => {
  return <span className={className}>Last save: {timestamp}</span>
}
LastUpdated.propTypes = {
  className: string,
  timestamp: string
}
const StyledLastUpdated = styled(LastUpdated)`
  flex: 1 0 100%;
  text-align: center;
  color: #188917;
`

const isQuestionRequired = (programQuestions, targetQuestions, answers, qId) => {
  if (!targetQuestions[qId]) return false
  const { controlQuestionId, controlAnswer } = programQuestions.find(({ id }) => id === qId)
  if (!controlQuestionId) return true
  if (!controlAnswer) return false
  return answers[controlQuestionId]
    ? answers[controlQuestionId] === controlAnswer
    : isQuestionRequired(programQuestions, targetQuestions, answers, controlQuestionId)
}

const getValidDropdownAnswer = (question, answers) => {
  const answer = !isEmpty(answers) && answers[question.id]
  if (!answer) return ''
  return question.options?.some(({ value }) => value === answer) ? answer : ''
}
const SellInCustomerProgramForm = ({
  isVolumeProgram,
  sellInTarget,
  sellInProgram,
  submitCustomerProgramUpdate,
  submitCustomerSellInException,
  setExceptionType,
  exceptionType,
  sendSellInRetailerEmail
}) => {
  const [submitError, setSubmitError] = useState(null)
  const navigate = useNavigate()

  const [online, setOnline] = useState(true)

  useEffect(() => {
    window.addEventListener('online', () => setOnline(true))
    window.addEventListener('offline', () => setOnline(false))
    return () => {
      window.removeEventListener('online', () => setOnline(true))
      window.removeEventListener('offline', () => setOnline(false))
    }
  }, [])

  const initialValues = useMemo(() => {
    const defaultValues = { status: sellInTarget.status }

    defaultValues.answers = sellInProgram.questions.reduce(
      (acc, { id, ref, type, options, controlQuestionId, controlAnswer }) => {
        const customerQ = sellInTarget.questions && sellInTarget.questions[id]
        if (!customerQ) return acc
        const isVisibleQuestion =
          !controlQuestionId ||
          (!isEmpty(sellInTarget.answers) && sellInTarget.answers[controlQuestionId] === controlAnswer)
        const readOnlyAnswer =
          type === 'readOnly' && sellInTarget.status === 'pending'
            ? customerQ.preset
            : sellInTarget?.answers?.[id] || ''
        const answer =
          type === 'readOnly' ? readOnlyAnswer : getValidDropdownAnswer({ id, options }, sellInTarget.answers)
        return {
          ...acc,
          [id]: isVisibleQuestion ? answer : ''
        }
      },
      {}
    )

    if (isVolumeProgram) {
      defaultValues.finalQtys = sellInTarget.skus.reduce(
        (acc, { productId, suggestedQty = 0, finalQty }) => ({
          ...acc,
          [`p${productId}`]: isNull(finalQty) ? suggestedQty : finalQty
        }),
        {}
      )
    }
    if (exceptionType) {
      defaultValues.exception = {}
    }
    if (exceptionType === AGREEMENT_TERMS) {
      defaultValues.exception.skus = sellInTarget.skus.reduce(
        (acc, { productId, minQty, maxQty }) => ({
          ...acc,
          [`p${productId}`]: { minQty, maxQty }
        }),
        {}
      )
    }

    if (exceptionType === AGREEMENT_TERMS) {
      defaultValues.exception.questions = Object.entries(sellInTarget.questions).reduce(
        (acc, [questionId, { max, preset }]) => ({
          ...acc,
          [questionId]: preset || max
        }),
        {}
      )
    }

    return defaultValues
  }, [sellInTarget, isVolumeProgram, exceptionType])

  const initialTouched = useMemo(() => {
    if (initialValues?.finalQtys) {
      const finalQtys = Object.keys(initialValues.finalQtys).reduce((acc, pId) => {
        return {
          ...acc,
          [pId]: true
        }
      }, {})
      return { finalQtys }
    }
  }, [initialValues?.finalQtys])

  const validate = useCallback(
    (values) => {
      const errors = {}

      if (isEmpty(values?.answers) || isEmpty(sellInProgram?.questions)) return errors

      const relevantAnswers = pickBy(values.answers, (ans, qId) => {
        return isQuestionRequired(sellInProgram.questions, sellInTarget.questions, values.answers, qId)
      })

      const hasUnansweredQuestions = Object.values(relevantAnswers).some((ans) => !ans)
      if (values.status === 'accepted' && hasUnansweredQuestions) {
        errors.status = 'All questions must be answered to accept'
      }
      return errors
    },
    [sellInTarget?.questions, sellInTarget?.answers, sellInProgram?.questions]
  )

  const submitCustomerProgramUpdateForm = async ({ status, answers, finalQtys }) => {
    const { customerId, sellInProgramId, skus } = sellInTarget
    const validAnswers = pickBy(answers, (ans) => ans !== '')
    const update = {
      customerId,
      sellInProgramId,
      status,
      customer: customerId,
      answers: isEmpty(validAnswers) ? null : validAnswers
    }
    if (status === 'accepted' && sellInTarget.status !== status) {
      update.acceptedAt = new Date().toISOString()
    }
    if (finalQtys) {
      const assortmentSkus =
        sellInProgram.fullAssortment && skus
          ? skus.filter(({ isAssortment }) => isAssortment).map(({ productId }) => productId)
          : []
      update.skus = Object.entries(finalQtys)
        .map(([pId, fQty]) => {
          const productId = pId.replace(/\D/g, '')
          const finalQty = status === 'rejected' ? null : +fQty || 0
          if (!finalQty && assortmentSkus.includes(productId)) return null
          return {
            customerId,
            sellInProgramId,
            productId,
            finalQty
          }
        })
        .filter(Boolean)
    }

    const redirect = () => navigate('..', { replace: true })

    return submitCustomerProgramUpdate(update, redirect)
  }

  const submitCustomerProgramExceptionForm = async ({ exception, status }) => {
    const { customerId, sellInProgramId } = sellInTarget
    const newException = {
      type: exceptionType,
      customerId,
      sellInProgramId,
      createdAt: new Date().toISOString(),
      requestComment: exception.requestComment
    }

    if (exceptionType === STATUS) {
      newException.requestedStatus = status
    }

    if (!isEmpty(exception.skus)) {
      const initialSkus = initialValues.exception.skus
      const diffSkus = pickBy(exception.skus, (value, pProductId) => {
        const { minQty, maxQty } = initialSkus[pProductId]
        return value.minQty !== minQty || value.maxQty !== maxQty
      })
      if (!isEmpty(diffSkus)) {
        newException.skus = diffSkus
      }
    }

    if (!isEmpty(exception.questions)) {
      const initialQuestions = initialValues.exception.questions
      const diffQuestions = pickBy(exception.questions, (value, questionId) => {
        const initialPreset = initialQuestions[questionId]
        return value !== initialPreset
      })
      if (!isEmpty(diffQuestions)) {
        newException.questions = diffQuestions
      }
    }
    const redirect = () => navigate('..', { replace: true })

    return submitCustomerSellInException(newException, redirect)
  }

  const submitSellInTargetForm = async (formValues, formikBag) => {
    const submitter = exceptionType ? submitCustomerProgramExceptionForm : submitCustomerProgramUpdateForm
    const redirect = () => navigate('..', { replace: true })
    return submitter(formValues, redirect)
      .then(() => {
        setExceptionType()
        setSubmitError(null)
      })
      .catch((error) => {
        setSubmitError(
          `Got an error when trying to submit the Sell-In Program answer, please try again!
          Error details : ${error.message}`
        )
      })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialTouched={initialTouched}
      onSubmit={submitSellInTargetForm}
      validate={validate}
      validateOnMount
    >
      {({ values, isSubmitting, isValid }) => {
        return (
          <Form>
            <Fieldset>
              {(!exceptionType || exceptionType === AGREEMENT_TERMS) && (
                <Questions
                  questions={sellInProgram.questions}
                  customerQuestions={sellInTarget.questions}
                  exceptionType={exceptionType}
                  isLocked={Boolean(exceptionType) && exceptionType !== AGREEMENT_TERMS}
                />
              )}
              {isVolumeProgram && (!exceptionType || exceptionType === AGREEMENT_TERMS) && (
                <FieldsetItem>
                  <SkuTable
                    skus={sellInTarget.skus}
                    isForm={!exceptionType || exceptionType === AGREEMENT_TERMS}
                    exceptionType={exceptionType}
                  />
                </FieldsetItem>
              )}
              {((!exceptionType && sellInTarget.status.toLowerCase() === 'pending') || exceptionType === STATUS) && (
                <FieldsetItem>
                  <Field
                    name="status"
                    component={SegmentControl}
                    options={[
                      { value: 'pending', label: 'Pending' },
                      { value: 'accepted', label: 'Accept' },
                      { value: 'rejected', label: 'Reject' }
                    ]}
                  />
                </FieldsetItem>
              )}
              {exceptionType && (
                <FieldsetItem>
                  <Field
                    label="Exception comment"
                    component={Input}
                    textarea
                    name="exception.requestComment"
                    validate={required}
                  />
                </FieldsetItem>
              )}
              {exceptionType ? (
                <FieldsetItem half>
                  <Button full secondary type="button" onClick={() => setExceptionType()} disabled={isSubmitting}>
                    Cancel
                  </Button>
                </FieldsetItem>
              ) : (
                <FieldsetItem half>
                  <Button full secondary type="button" onClick={() => setExceptionType(AGREEMENT_TERMS)}>
                    Request Exception
                  </Button>
                </FieldsetItem>
              )}
              <FieldsetItem half>
                <Button
                  full
                  color={sellInProgram.color}
                  type="submit"
                  disabled={!isValid || !online}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </FieldsetItem>
              {!online && <GlobalAlert>You are offline, you will not be able to save</GlobalAlert>}
              {submitError && <GlobalAlert>{submitError}</GlobalAlert>}
              <FieldsetItem half>
                <StyledLastUpdated timestamp={moment(sellInTarget.updatedAt).format('MMM D h:mm a')} />
              </FieldsetItem>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

SellInCustomerProgramForm.propTypes = {
  isVolumeProgram: bool,
  sellInTarget: object,
  sellInProgram: object,
  submitCustomerProgramUpdate: func,
  submitCustomerSellInException: func,
  setExceptionType: func,
  exceptionType: string,
  sendSellInRetailerEmail: func
}

export default connect(null, { submitCustomerProgramUpdate, submitCustomerSellInException })(SellInCustomerProgramForm)
