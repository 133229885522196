import React, { useContext, useEffect, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { fetchUser, setLoadingTrue, setRedirectTo } from 'store/auth/actions'

import refetchWorker from 'utils/refetchWorker'

const needsAuth = (Component) => {
  const Base = (props) => {
    const { fetchUser, setLoadingTrue, setRedirectTo } = props
    const location = useLocation()
    const context = useContext(LangContext)

    const isLoggedIn = useSelector(({ auth }) => auth.loggedIn)
    const isUserLoading = useSelector(({ auth }) => auth.loading)
    const lastUserFetch = useSelector(({ auth }) => auth.lastUserFetch)
    const employee = useSelector(({ auth }) => auth.user)
    const redirectTo = useSelector(({ auth }) => auth.redirectTo)

    const prevLocation = useRef(location.pathname)
    useEffect(() => {
      const changeIsWithinCustomerCycle =
        prevLocation.current.match(/^\/customers\/\d{8}\/cycle/) &&
        location.pathname.match(/^\/customers\/\d{8}\/cycle/)
      if (!location.pathname.match(/^\/customers\/?$/) && !changeIsWithinCustomerCycle) {
        window.scroll(0, 0)
      }
      // update ref
      prevLocation.current = location.pathname
    }, [location])

    useEffect(() => {
      employee?.preferredLanguage && context.setLang(employee.preferredLanguage.substring(0, 2))
    }, [context, employee?.preferredLanguage])

    useEffect(() => {
      if (refetchWorker) {
        if (!employee) setLoadingTrue()
        refetchWorker.postMessage({ lastFetch: lastUserFetch })
      } else {
        if (!employee) fetchUser(lastUserFetch)
      }
    }, [employee, fetchUser, lastUserFetch, setLoadingTrue])

    function render() {
      if (isLoggedIn && redirectTo) {
        const beforeUpdate = redirectTo
        setRedirectTo(null)
        return <Navigate to={beforeUpdate} />
      }
      if (localStorage.getItem('token') || isLoggedIn || isUserLoading) {
        return <Component {...props} isUserLoading={isUserLoading} />
      }

      // const navigatedFromExternalSite = document.referrer && !document.referrer.startsWith(window.location.origin)
      if (redirectTo !== location?.pathname) {
        const redirectToLocation = redirectTo === '/' ? null : location?.pathname + location?.search
        setRedirectTo(redirectToLocation)
      }
      return (
        <Navigate
          to={{
            pathname: '/auth/login'
          }}
        />
      )
    }

    return render()
  }

  Base.propTypes = {
    fetchUser: func,
    setRedirectTo: func,
    setLoadingTrue: func
  }

  const mapDispatchToProps = {
    fetchUser,
    setLoadingTrue,
    setRedirectTo
  }

  return connect(null, mapDispatchToProps)(Base)
}

export default needsAuth
