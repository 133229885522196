import queryString from 'query-string'

import { api } from 'store/api'

export const fetchPlanningHealthCheck = ({ id, sectorLevel, productType, timeframe, vapeChannel, period }) =>
  api.get(
    `/health-check?${queryString.stringify(
      {
        id,
        sectorLevel,
        type: productType,
        timeframe,
        vapeChannel,
        period
      },
      { skipNull: true }
    )}`
  )
