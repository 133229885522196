import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, number, object } from 'prop-types'

import LangContext from 'context/LangContext'

import { getCustomerOrders } from 'store/customers/actions'
import { customerOrders, getPerfData, orderComplianceThresholds } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import Button from 'components/button/Button'
import Card from 'components/card'
import SuggestedAmounts from 'components/close/SuggestedAmounts'
import EmptyState from 'components/EmptyState'
import SAQTable from 'components/SAQTable'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { formatCurrency } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const TotalOpportunitiesCard = ({ span, getCustomerOrders, recommendedQtyByType }) => {
  const { translate } = useContext(LangContext)
  const { sectorId: customerId } = useParams()
  const [error, setError] = useState()

  const cardProps = {
    title: translate('common.total'),
    span,
    recommendedQtyByType
  }

  const { totalPrice: grandTotalPrice, totalQty: grandTotalQty } = recommendedQtyByType.grandTotal
  const { totalPrice: fmcTotalPrice, totalQty: fmcTotalQty } = recommendedQtyByType.fmc
  const { totalPrice: vapeTotalPrice, totalQty: vapeTotalQty } = recommendedQtyByType.vape

  const previousOrders = useSelector((state) => customerOrders(state, { customerId }))
  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_ORDERS, { customerId })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const customerOrderComplianceThresholds = useSelector((state) => orderComplianceThresholds(state, { customerId }))
  const { AWR13, WTD } = useSelector((state) => getPerfData(state, { customerId }))

  useEffect(() => {
    if (customerId) {
      setError()
      getCustomerOrders({ customerId, dataKey }).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [customerId])

  const getContent = () => {
    if (isLoading) {
      return <Spinner icon="spinner" />
    }

    if (error) {
      return <EmptyState title="An error occured" subtitle={error} />
    }

    return (
      <>
        <div>
          <Button primary icon="cart" to="./cart">
            {translate('common.reviewCart')}
          </Button>
        </div>
        <SAQTable title={translate('common.lastOrder')} orders={previousOrders} />
      </>
    )
  }

  return (
    <Card {...cardProps}>
      <SuggestedAmounts
        title={translate('close.recommendations.totalSuggestedAmounts')}
        qty={grandTotalQty}
        value={grandTotalPrice}
      />

      <div className="flex flex-col gap-2 font-medium">
        <p className="text-2xs uppercase text-slate-500 ">
          {translate('close.recommendations.suggestedAmountsByCategory')}
        </p>
        <div className="flex flex-col gap-3 rounded-md bg-slate-50 p-4 @container">
          <div className="flex items-center gap-4">
            <p className="w-full text-slate-700">{translate('common.fmc')}</p>
            <div className="flex shrink-0 flex-col gap-2 @[18rem]:flex-row @[18rem]:gap-4 ">
              <p className="text-right">{fmcTotalQty} units</p>
              <p className="text-right">{formatCurrency(fmcTotalPrice)}</p>
            </div>
          </div>
          <hr />
          <div className="flex items-center gap-4">
            <p className="w-full text-slate-700">{translate('common.vape')}</p>
            <div className="flex shrink-0 flex-col gap-2 @[18rem]:flex-row @[18rem]:gap-4 ">
              <p className="text-right">{vapeTotalQty} units</p>
              <p className="text-right">{formatCurrency(vapeTotalPrice)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col items-center gap-4">{getContent()}</div>

      <div className="gap0.5 flex flex-col rounded-md bg-slate-50 p-4 text-xs font-medium text-slate-700">
        <p>
          AWR13: <span className="font-semibold text-sky-600">{AWR13 || '0'}</span>
        </p>
        <p>
          WTD: <span className="font-semibold text-sky-600">{WTD || '0'}</span>
        </p>
        <p>
          <span className="font-semibold text-sky-600">{customerOrderComplianceThresholds.emergency}</span>{' '}
          {translate('app.actions.orders.emergencyTreshold')}
        </p>
        <p>
          <span className="font-semibold text-sky-600">{customerOrderComplianceThresholds.saq}</span>{' '}
          {translate('app.actions.orders.saqTreshold')}
        </p>
      </div>
    </Card>
  )
}

TotalOpportunitiesCard.propTypes = {
  span: number,
  getCustomerOrders: func,
  recommendedQtyByType: object
}

export default connect(null, { getCustomerOrders })(TotalOpportunitiesCard)
