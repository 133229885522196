import uniqBy from 'lodash/uniqBy'

import { addEntities, mergeEntities, setEntities } from 'store/actions'

import {
  addMileageToEmployee,
  addNewImages,
  addSyncError,
  disableTracking,
  fetchUser,
  logout,
  removeImage,
  removeMileageToEmployee,
  removeSyncError,
  requestLogin,
  setCoords,
  setCurrentTerritoryId,
  setLastOfflineError,
  setLoadingFalse,
  setLoadingTrue,
  setLocationLoading,
  setRedirectTo,
  setTracker,
  submitNewPassword,
  updateRecentSearches,
  updateUserReadAlerts,
  updateUserSettings,
  verifySignup
} from './actions'

const initialState = {
  loggedIn: false,
  loading: false,
  territoryLoading: false,
  locationLoading: false,
  coords: null,
  tracker: null,
  user: null,
  lastUserFetch: null,
  recentSearches: [],
  currentTerritoryId: null,
  newImages: [],
  syncErrors: [],
  redirectTo: null
}

const getTerritoryId = (state, user) => {
  if (!user) return state.currentTerritoryId
  return user.territories?.length && (!state.currentTerritoryId || !user.territories.includes(state.currentTerritoryId))
    ? user.territories[0]
    : state.currentTerritoryId
}

const storeEntities = (state, { payload: { user = {}, territories } }) => {
  const userLoadedFields = {
    ...user,
    loggedIn: true,
    lastUserFetch: +new Date(),
    currentTerritoryId: getTerritoryId(state, user.user)
  }
  return {
    ...state,
    ...(user.user ? userLoadedFields : null),
    loading: false,
    territoryLoading: territories ? false : state.territoryLoading
  }
}

const ACTION_HANDLERS = {
  [addEntities]: storeEntities,
  [setEntities]: storeEntities,
  [mergeEntities]: storeEntities,
  [fetchUser]: (state, action) => ({
    ...state,
    loading: true,
    lastUserFetch: null
  }),
  [requestLogin]: (state, action) => ({
    ...state,
    loading: true,
    lastUserFetch: null
  }),
  [verifySignup]: (state, action) => ({
    ...state,
    loading: true,
    lastUserFetch: null
  }),
  [submitNewPassword]: (state, action) => ({
    ...state,
    loading: true,
    lastUserFetch: null
  }),
  [logout]: ({ tracker, recentSearches, redirectTo }, { payload }) => {
    window.navigator.geolocation.clearWatch(tracker)
    return {
      ...initialState,
      loggedIn: false,
      lastUserFetch: +new Date(),
      recentSearches,
      redirectTo: payload?.noRedirect ? '/' : redirectTo
    }
  },
  [setLoadingTrue]: (state, action) => ({
    ...state,
    loading: true
  }),
  [setLoadingFalse]: (state, action) => ({
    ...state,
    loading: false
  }),
  [setLastOfflineError]: (state, action) => ({
    ...state,
    lastOfflineError: action.payload
  }),
  [setLocationLoading]: (state, action) => ({
    ...state,
    locationLoading: action.payload
  }),
  [setLocationLoading]: (state, action) => ({
    ...state,
    locationLoading: action.payload
  }),
  [setTracker]: (state, action) => ({
    ...state,
    tracker: action.payload
  }),
  [setCoords]: (state, action) => ({
    ...state,
    coords: action.payload,
    locationLoading: false
  }),
  [disableTracking]: ({ tracker, ...state }) => {
    window.navigator.geolocation.clearWatch(tracker)
    return {
      ...state,
      tracker: null,
      coords: null,
      locationLoading: false
    }
  },
  [updateRecentSearches]: ({ recentSearches, ...state }, action) => ({
    ...state,
    recentSearches: uniqBy([action.payload, ...recentSearches], (q) => q.toLowerCase()).slice(0, 10)
  }),
  [setCurrentTerritoryId]: (state, action) => ({
    ...state,
    currentTerritoryId: action.payload
  }),
  [updateUserSettings]: ({ user, ...state }, action) => ({
    ...state,
    user: {
      ...user,
      ...action.payload
    }
  }),
  [addNewImages]: ({ newImages, ...state }, action) => ({
    ...state,
    newImages: [...newImages, ...action.payload]
  }),
  [removeImage]: ({ newImages, ...state }, action) => ({
    ...state,
    newImages: newImages.filter((img) => img.preview !== action.payload)
  }),
  [addSyncError]: (state, action) => ({
    ...state,
    syncErrors: [...state.syncErrors, action.payload]
  }),
  [removeSyncError]: (state, { payload }) => ({
    ...state,
    syncErrors: state.syncErrors.filter(({ id, type }) => !(id === payload.id && type === payload.type))
  }),
  [updateUserReadAlerts]: (state, { payload }) => ({
    ...state,
    user: {
      ...state.user,
      readAlerts: uniqBy([payload, ...state.user.readAlerts], 'id')
    }
  }),
  [setRedirectTo]: (state, { payload }) => ({
    ...state,
    redirectTo: payload === '/settings' ? null : payload
  }),
  [addMileageToEmployee]: (state, { payload }) => {
    if (!state?.user?.mileage) return state
    return {
      ...state,
      user: {
        ...state.user,
        mileage: uniqBy([payload, ...state.user.mileage], 'id')
      }
    }
  },
  [removeMileageToEmployee]: (state, { payload }) => {
    if (!state?.user?.mileage || !payload.id) return state
    return {
      ...state,
      user: {
        ...state.user,
        mileage: state.user.mileage.filter(({ id }) => id !== payload.id)
      }
    }
  }
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
