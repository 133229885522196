import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { bool, func, number, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchDistroBrand } from 'store/distro/actions'
import { brandDistroData } from 'store/distro/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import DistroContext from './DistroContext'

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

function toFixed(value) {
  return Math.round((value || 0) * 100) / 100
}

const AmplifyDistroBrandCard = ({ span, fetchDistroBrand, showCustomerView, vapeCategory = 'all' }) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const { vapeChannel, period } = useContext(DistroContext)

  const [error, setError] = useState()
  const [sortBy, setSortBy] = useState({ column: 'weighteddistro', order: 'desc' })
  const mounted = useRef(false)

  const brandData = useSelector((state) =>
    brandDistroData(state, {
      activeProductType: currentProductType,
      vapeCategory,
      vapeChannel,
      sortBy: sortBy.column,
      direction: sortBy.order,
      period
    })
  )

  const brandLabel = currentProductType === 'fmc' ? 'app.brandPerformance' : 'app.brandVariant'

  const dataKey = createDataKey(DATAKEY_TYPES.DISTRO_BRAND, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType,
    vapeCategory,
    vapeChannel,
    sortBy: sortBy.column,
    direction: sortBy.order,
    period
  })

  const isBrandLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchDistroBrand(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType,
          vapeCategory,
          vapeChannel,
          sortBy: sortBy.column,
          direction: sortBy.order,
          period
        },
        dataKey
      ).catch((error) => {
        if (mounted.current) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType, vapeCategory, vapeChannel, sortBy, period])

  const graphRows = useMemo(() => {
    if (brandData.length) {
      return brandData.map((d) => {
        if (showCustomerView) {
          return {
            brand: d.name,
            skudistro: (
              <DataCompare
                last={`${toFixed(d.skuDistro)}`}
                isPercent
                variation={d.skuDistroDiff}
                variationBefore
                stacked
              />
            )
          }
        }

        return {
          brand: d.name,
          weighteddistro: (
            <DataCompare
              last={`${toFixed(d.salesDistro)}`}
              isPercent
              variation={d.salesDistroDiff}
              variationBefore
              stacked
            />
          ),
          storedistro: (
            <DataCompare
              last={`${toFixed(d.storeDistro)}`}
              isPercent
              variation={d.storeDistroDiff}
              variationBefore
              stacked
            />
          )
        }
      })
    }

    return []
  }, [brandData])

  const COLS = [
    {
      field: 'brand',
      headerName: translate(brandLabel)
    },
    {
      field: 'weighteddistro',
      headerName: translate('app.wdistro')
    },
    {
      field: 'storedistro',
      headerName: translate('app.storeDistro')
    }
  ]

  const CUSTOMER_COLS = [
    {
      field: 'brand',
      headerName: translate(brandLabel)
    },
    {
      field: 'skudistro',
      headerName: translate('app.SKUDistro')
    }
  ]

  const setSorted = (columnClicked) => {
    if (columnClicked === sortBy.column) {
      if (sortBy.order === 'desc') {
        setSortBy({ column: columnClicked, order: 'asc' })
      } else {
        setSortBy({ column: columnClicked, order: 'desc' })
      }
    } else {
      setSortBy({ column: columnClicked, order: 'desc' })
    }
  }

  const renderContent = (rows) => {
    if (isBrandLoading) {
      return (
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      )
    }

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    return rows.length ? (
      <div className="max-h-[300px] overflow-y-auto">
        <DataTable
          onColumnClick={setSorted}
          activeColumn={sortBy}
          unClickableColumns={['brand']}
          columns={showCustomerView ? CUSTOMER_COLS : COLS}
          rows={graphRows}
          fillContainer
        />
      </div>
    ) : (
      <EmptyState title={translate('app.warn.noData')} />
    )
  }

  if (error) {
    return <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />
  }

  return (
    <Card title={translate(brandLabel)} span={span} displayAmplify={false}>
      {renderContent(graphRows)}
    </Card>
  )
}

AmplifyDistroBrandCard.propTypes = {
  span: number,
  fetchDistroBrand: func,
  vapeCategory: string,
  showCustomerView: bool
}

export default connect(null, { fetchDistroBrand })(AmplifyDistroBrandCard)
