import React from 'react'
import { array, func, string } from 'prop-types'
import styled from 'styled-components'

import { WrappedSpinner } from 'components/Spinner'

import { borderColor, secondaryColor, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3, tinyFontSize } from 'styles/typography'

const Container = styled.div`
  padding: ${spacing.medium};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  margin-bottom: ${spacing.medium};
  background-color: ${white};

  ${media.breakpoint`
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};
`

const Inner = styled.div`
  display: flex;
  gap: ${spacing.medium};
  flex: 1 1 0px;
  flex-wrap: wrap;
  align-items: start;
`

const Item = styled.div`
  flex: 1;
  text-align: center;
`

const Number = styled(H3)`
  color: ${(props) => props.color};
  margin-bottom: 0;
`

const Label = styled.span`
  display: block;
  font-size: ${tinyFontSize};
  white-space: pre-line;
`

const returnValue = (val) => val
const StatsDash = ({
  stats,
  labelKey = 'label',
  valueKey = 'value',
  valueFormatter = returnValue,
  color = secondaryColor
}) => (
  <Container>
    <Inner>
      {stats.map((stat, idx) => {
        const label = stat[labelKey]
        const value = stat[valueKey]
        const loading = stat.loading
        return (
          <Item key={idx}>
            {loading ? (
              <WrappedSpinner icon="spinner" />
            ) : (
              <>
                <Number color={color}>{valueFormatter(value)}</Number>
                <Label>{label}</Label>
              </>
            )}
          </Item>
        )
      })}
    </Inner>
  </Container>
)

StatsDash.propTypes = {
  stats: array.isRequired,
  labelKey: string,
  valueKey: string,
  valueFormatter: func,
  color: string
}

export default StatsDash
