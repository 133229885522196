export function storeFootprint(state, { payload: { id, productType, ...data } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        storeFootprint: {
          ...state[id]?.priceCaptureCompliance?.storeFootprint,
          [productType]: data
        }
      }
    }
  })
}

export function brandCompletion(state, { payload: { id, brandCompletion, productType } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        [`brandCompletion-${productType}`]: brandCompletion
      }
    }
  })
}

export function filters(state, { payload: { id, filters, productType } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        filters: {
          ...state[id]?.priceCaptureCompliance?.filters,
          [productType]: {
            ...filters
          }
        }
      }
    }
  })
}

export function geography(
  state,
  { payload: { id, geography, productType, offset, filters, data, sortBy, sortDirection } }
) {
  const {
    channel = 'all',
    ownershipType = 'all',
    banner = 'all',
    headoffice = 'all',
    tier = 'all',
    material = 'all'
  } = filters

  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        geographies: {
          ...state[id]?.priceCaptureCompliance?.geographies,
          [`geography-${geography}-${productType}-${channel}-${ownershipType}-${banner}-${headoffice}-${tier}-${material}-offset-${offset}-${sortBy}-${sortDirection}`]:
            data
        }
      }
    }
  })
}

export function strategyCompliance(state, { payload: { id, strategyCompliance, productType } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        [`strategy-compliance-${productType}`]: strategyCompliance
      }
    }
  })
}

export function edlpCompliance(state, { payload: { id, edlpCompliance } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        edlpCompliance
      }
    }
  })
}

export function priceComplianceGeographyFilters(
  state,
  { payload: { id, filters: priceComplianceGeographyFilters, productType } }
) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        priceComplianceGeographyFilters: {
          ...state[id]?.priceCaptureCompliance.priceComplianceGeographyFilters,
          [productType]: priceComplianceGeographyFilters
        }
      }
    }
  })
}

export function priceComplianceGeography(
  state,
  {
    payload: {
      id,
      geography,
      productType,
      data,
      sortBy,
      direction,
      filters: {
        channel = 'all',
        headoffice = 'all',
        banner = 'all',
        packType = 'all',
        packCount = 'all',
        strategyType = 'all',
        ownershipType = 'all',
        productName = 'all',
        tier = 'all',
        strategyName = 'all',
        offset
      }
    }
  }
) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        priceComplianceGeographies: {
          ...state[id]?.priceCaptureCompliance?.priceComplianceGeographies,
          [`geography-${geography}-${productType}-${channel}-${headoffice}-${banner}-${packType}-${packCount}-${strategyType}-${ownershipType}-${tier}-${productName}-${strategyName}-${offset}-${sortBy}-${direction}`]:
            data
        }
      }
    }
  })
}
