import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Outlet, useParams } from 'react-router-dom'
import moment from 'moment'
import { object, string } from 'prop-types'

import * as pricingSelectors from 'store/pricings/selectors'

import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import PriceCaptureTable, { PriceCaptureTableRow } from 'components/PriceCaptureTable'
import Status from 'components/Status'

import { formatCurrency } from 'utils/formatters'

import { tinyFontSize } from 'styles/typography'

function getCaptureDate({ status, updatedAt }) {
  if (status === 'expired') return 'OUTDATED'
  return updatedAt ? moment(updatedAt).parseZone().format('MM/DD/YY') : updatedAt
}

const PriceCaptures = () => {
  const { sectorId: customerId } = useParams()

  const pricings = useSelector((state) => pricingSelectors.customerPricing(state, { customerId }))
  const nextCapture = useSelector((state) => pricingSelectors.nextCaptureUpc(state, { customerId }))

  if (!pricings.length) return <EmptyState title="No price requests" subtitle="We have no pricing data." />

  return (
    <Container>
      <ButtonGroup>
        <Button to={nextCapture} primary>
          Update all prices
        </Button>
      </ButtonGroup>

      <PriceCaptureTable captureLink="nextCapture">
        {pricings.map((pricing, i) => {
          const pricingName = [pricing.shortBrand, pricing.packSize, pricing.packCount].filter(Boolean).join(' ')

          return (
            <PriceCaptureTableRow key={i}>
              <td className="notranslate">
                <Link
                  className="block w-fit rounded-md p-0.5 px-1.5 text-brand-800 hover:bg-brand-100 hover:text-brand-600"
                  to={pricing.upc}
                >
                  {pricingName}
                </Link>
              </td>
              <td>
                <BeforeTax status={pricing.status} entity={pricing} />
              </td>
            </PriceCaptureTableRow>
          )
        })}
      </PriceCaptureTable>
      <Outlet />
    </Container>
  )
}

export default PriceCaptures

const Subscript = ({ label, value }) => {
  return (
    <div style={{ fontSize: tinyFontSize }}>
      {value && (
        <>
          <b>{label}</b> {value}
        </>
      )}
    </div>
  )
}

Subscript.propTypes = {
  label: string,
  value: string
}

const BeforeTax = ({ entity }) => {
  return (
    <>
      <Status status={entity?.status || 'noStatus'} label={entity?.price ? formatCurrency(entity?.price) : '-'} />
      <Subscript label={'On:'} value={getCaptureDate(entity || {})} />
    </>
  )
}

BeforeTax.propTypes = {
  status: string.isRequired,
  entity: object.isRequired
}
