import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import LangContext from 'context/LangContext'

import { createCustomerReminder } from 'store/customers/actions'

import Button from 'components/button/Button'
import ErrorMessage from 'components/ErrorMessage'
import Input from 'components/Input'
import { Modal } from 'components/Modal'

import { getErrorMessage } from 'utils/helpers'

const RemindersModal = ({ isReminderModalOpen, setIsReminderModalOpen, createCustomerReminder }) => {
  const { sectorId: customerId } = useParams()
  const { translate } = useContext(LangContext)

  const [reminderInput, setReminderInput] = useState('')
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const handleAddReminder = async () => {
    if (!reminderInput.trim()) return
    setIsLoading(true)
    setError(null)

    await createCustomerReminder({
      customerId,
      reminder: reminderInput.trim()
    })
      .then(() => {
        setReminderInput('')
        setIsReminderModalOpen(false)
      })
      .catch((error) => setError(getErrorMessage(error)))
      .finally(() => setIsLoading(false))
  }
  return (
    <Modal
      title={translate('evaluate.objectives.newReminder.title')}
      open={isReminderModalOpen}
      onOpenChange={setIsReminderModalOpen}
      footer={
        <div className="flex gap-2">
          <Button secondary onClick={() => setIsReminderModalOpen(false)}>
            {translate('common.cancel')}
          </Button>
          <Button primary onClick={handleAddReminder} isLoading={isLoading}>
            {translate('common.addReminder')}
          </Button>
        </div>
      }
    >
      <Input
        value={reminderInput}
        onChange={(e) => setReminderInput(e.target.value)}
        placeholder={translate('common.reminderPlaceholder')}
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Modal>
  )
}

RemindersModal.propTypes = {
  isReminderModalOpen: PropTypes.string.isRequired,
  setIsReminderModalOpen: PropTypes.func.isRequired,
  createCustomerReminder: PropTypes.func.isRequired
}

export default connect(null, {
  createCustomerReminder
})(RemindersModal)
