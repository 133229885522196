import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import config from 'config'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import ClickableTile from 'components/ClickableTile'

import { SECTOR_LEVELS } from 'utils/constants'
import { cn } from 'utils/styling'

const Container = tw.div`max-md:pt-20 gap-4`

const CloseIndex = () => {
  const { sectorType } = useParams()
  const { translate } = useContext(LangContext)

  const TILES = [
    {
      title: translate('close.consumerOffer'),
      description: translate('close.consumerOffer.description'),
      to: 'consumer-offer',
      order: 1,
      comingSoon: false,
      disabled: sectorType === SECTOR_LEVELS.TERRITORY
    },
    {
      title: translate('close.i2a'),
      description: translate('close.i2a.description'),
      to: 'insight-to-action',
      order: 2,
      comingSoon: !config.featureFlags.i2a
    }
  ]

  return (
    <Container>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <ClickableTile
          content={{
            title: translate('close.closeOpportunities'),
            description: translate('close.closeOpportunities.description'),
            to: 'close-opportunities',
            disabled: sectorType !== SECTOR_LEVELS.CUSTOMER || !config.featureFlags.closeOpportunities
          }}
          comingSoon={!config.featureFlags.closeOpportunities}
        />
      </div>

      <div className={cn('mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4')}>
        <hr className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4" />

        {TILES.map((tile) => (
          <ClickableTile key={tile.title} content={tile} comingSoon={tile.comingSoon} />
        ))}
      </div>
    </Container>
  )
}

export default CloseIndex
